import {
  faFacebookF,
  faInstagram,
  faSnapchatGhost,
  faTwitter,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { faBars, faComments, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Container, Row } from "react-bootstrap";
import {  NavLink } from "react-router-dom";
import { getCookie } from "../../../Auth/ForCookie";
import "./index.scss";
import { getData } from "../../../../Services/DataService";
import axios from "axios";
import { toast } from "react-toastify";

const TopBar = ({ social, data, canMessage }) => {
  const [unReadChatCount, setUnReadChatCount] = useState(0);
  const showCats = () => {
    let element = document.getElementById("menuToggle");
    element.classList.toggle("showMenu");
  };


  const clearCookies = () => {
    const cookies = document.cookie.split(";");

    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i];
      const eqPos = cookie.indexOf("=");
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/`;
    }
  };


  const BaseUrl = process.env.REACT_APP_API_ENDPOINT;

  const handleLogOut = async () => {
    try {
      await axios
        .post(`${process.env.REACT_APP_API_ENDPOINT}/api/logout`)
        .then((response) => {
          clearCookies();
        })
        .then(() => clearCookies());
    } catch (error) {
      toast.error("حدث خطأ ما", {
        toastId: "logoutError",
      });
    } finally {
      clearCookies();
      window.location.href = "/";
    }
  };
  useEffect(() => {
    getCookie("loginned") &&
      getData(`api/unread_messages_count`).then(
        (response) => {
          const data = response?.data?.data;
          setUnReadChatCount(data);
        },
        (error) => {
          setUnReadChatCount(0);
        }
      );
    getCookie("loginned") &&
      setInterval(() => {
        getData(`api/unread_messages_count`).then(
          (response) => {
            const data = response?.data?.data;
            setUnReadChatCount(data);
          },
          (error) => {
            setUnReadChatCount(0);
          }
        );
      }, 600000);
  }, []);

  return (
    <>
      <div className="topBarParent">
        <Container>
          <Row className="topBarRow">
            {getCookie("loginned") !== "true" ? (
              <div className="login-parent">
                <NavLink to="/register" className="login-btn btn">
                  تسجيل عضوية
                </NavLink>
                <NavLink
                  to="/login"
                  className="btn register-btn inline-centerd"
                >
                  <span>
                    <FontAwesomeIcon icon={faUser} className="register-icon" />
                  </span>
                  <p className="t-none"> تسجيل دخول </p>
                </NavLink>
              </div>
            ) : null}
            {getCookie("loginned") === "true" && (
              <div className="login-parent loginned">
                <div className="user-info">
                  <div className="cont-img">
                    <NavLink to={`/profile/${getCookie("userId")}`}>
                      <img src={`${getCookie("userimage")}`} alt="" />
                    </NavLink>
                  </div>
                  <div className="cont-txt">
                    <NavLink to={`/profile/${getCookie("userId")}`}>
                      {getCookie("userName")}
                    </NavLink>
                  </div>
                </div>

                <div className="messages-parent">
                  {canMessage === 1 ? (
                    <a href="/messages?tab=General" className="messages-btn">
                      <span className="messages-count">{unReadChatCount}</span>
                      <FontAwesomeIcon icon={faComments} />
                    </a>
                  ) : null}
                </div>

                <div
                  onClick={handleLogOut}
                  className="btn register-btn inline-centerd"
                >
                  <span>
                    <FontAwesomeIcon icon={faUser} className="register-icon" />
                  </span>
                  <p> تسجيل خروج </p>
                </div>
              </div>
            )}

            <div className="t-show menu-control" onClick={showCats}>
              <FontAwesomeIcon icon={faBars} className="menu-show-control" />
            </div>
            {social && (
              <div className="social-parent t-none">
                <a
                  href={social?.facebook && social?.facebook.toString()}
                  className="social-link"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon icon={faFacebookF} />
                </a>
                <a
                  href={social?.instagram && social?.instagram.toString()}
                  className="social-link"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon icon={faInstagram} />
                </a>
                <a
                  href={social?.twitter && social?.twitter.toString()}
                  className="social-link"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon icon={faTwitter} />
                </a>
                <a
                  href={social?.youtube && social?.youtube.toString()}
                  className="social-link"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon icon={faYoutube} />
                </a>
                <a
                  href={social?.snapchat && social?.snapchat.toString()}
                  className="social-link"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon icon={faSnapchatGhost} />
                </a>
              </div>
            )}
          </Row>
        </Container>
      </div>
    </>
  );
};

export default TopBar;
