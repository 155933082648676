import { getCookie } from "../../../Components/Auth/ForCookie";
import {
  faChevronLeft,
  faChevronRight,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import LoadingIndicator from "../../../Components/Blocks/Loading/Content";
import { getData } from "../../../Services/DataService";
import "./index.scss";
import Cookies from "universal-cookie";
const AuthorServicesProfile = (props) => {
  const [servicesData, setServicesData] = useState([]);
  const [dataDeal, setDataDeal] = useState(0);
  const [currentPage, setCurrentPage] = useState(true);
  const [nextPage, setNextPage] = useState(true);
  const [prevPage, setPrevPage] = useState(true);
  const [LastPage, setLastPage] = useState(true);
  const [totalPages, setTotalPages] = useState(true);
  const [currentLink, setCurrentLink] = useState(true);
  const [last_page, setLast_page] = useState(true);
  const [Pages, setPages] = useState([]);
  const [loading, setLoading] = useState(true);

  const cookies = new Cookies();
  let canService = cookies.get("service");

  useEffect(() => {
    //request to get the books of category
    getData(`api/user-services/${props.userId}`).then(
      (response) => {
        const data = response.data.data;
        setServicesData(data.data);
        setCurrentPage(data.current_page);
        setNextPage(data.next_page_url);
        setPrevPage(data.prev_page_url);
        setLastPage(data.last_page_url);
        setTotalPages(data.last_page);
        setCurrentLink(data.path);
        setLast_page(data.last_page);
        setPages(response.data.pages);
        setLoading(false);
      },
      (error) => {
        setServicesData([]);
        setLoading(false);
      }
    );
  }, [props.userId]);

  // handle change in content according to pagination
  const fillData = (url) => {
    getData(url).then(
      (response) => {
        const data = response.data.data;
        setServicesData(data.data);
        setCurrentPage(data.current_page);
        setNextPage(data.next_page_url);
        setPrevPage(data.prev_page_url);
        setLastPage(data.last_page_url);
        setTotalPages(data.last_page);
        setCurrentLink(data.path);
        setLast_page(data.last_page);
        setPages(response.data.pages);
        setLoading(false);
        window.scrollTo({ top: 0, behavior: "smooth" });
      },
      (error) => {
        setServicesData([]);
        window.scrollTo({ top: 0, behavior: "smooth" });
      }
    );
  };

  // pagination functions

  const handleNextPage = (id) => {
    fillData(nextPage);
  };

  const handlePrevPage = () => {
    fillData(prevPage);
  };

  const handleGoToPage = (id) => {
    let pageNumber = id;
    fillData(`${currentLink}?page=${pageNumber}`);
  };

  const pages = Pages.length
    ? Pages.map((page, index) => {
        return (
          <li
            key={index}
            className={Number(currentPage) === page ? `active` : ""}
            onClick={() => handleGoToPage(page)}
          >
            <button>{page}</button>
          </li>
        );
      })
    : null;

  const loopOnServices = servicesData.length ? (
    servicesData.map((service) => {
      return (
        <Col xs={12} lg={3} key={service.id} className="service-slide-cont">
          <div className="cont-txt">
            <h2>
              <NavLink
                to={`/deals/${service.id}`}
                dangerouslySetInnerHTML={{
                  __html:
                    service.title.ar.length > 25
                      ? `${service.title.ar.slice(0, 25)}...`
                      : service.title.ar,
                }}
              ></NavLink>
            </h2>
            <p
              dangerouslySetInnerHTML={{
                __html: service.content.ar.slice(0, 50).concat("..."),
              }}
            ></p>
          </div>
          <div className="cont-btn">
            <NavLink to={`/deals/${service.id}`}>طلب الخدمة</NavLink>
          </div>
        </Col>
      );
    })
  ) : (
    <Col xs={12} lg={7} className="no-service-show mx-auto">
      <div className="cont-txt">
        <p>
          عذراً لا يوجد لديك أي صفقات مقدمة
          <br />
          يمكنك إضافة صفقة جديدة عن طريق الضغط على زر إضافة صفقة
        </p>
        {canService == 1 && (
          <NavLink to="/deals/add" className="red-btn">
            <FontAwesomeIcon icon={faPlus} className="plus-icon" />
            إضافة صفقة
          </NavLink>
        )}
      </div>
    </Col>
  );

  return (
    <>
      {servicesData.length > 0 &&
      getCookie("userId") === props.userId &&
      canService == 1 ? (
        <Row>
          <Col xs={12}>
            <div className="add-service">
              <NavLink to="/deals/add" className="red-btn">
                <FontAwesomeIcon icon={faPlus} className="plus-icon" />
                إضافة صفقة
              </NavLink>
            </div>
          </Col>
        </Row>
      ) : null}

      <Row className="author-services-parent">
        {loading ? <LoadingIndicator /> : loopOnServices}
      </Row>

      <Row>
        <Col xs={12} className="pagination-parent-books">
          {parseInt(totalPages) > 1 ? (
            <ul className="pagination-parent flx-centered">
              <li className="first-page">
                <button
                  onClick={handlePrevPage}
                  className={currentPage == "1" ? `disabled-btn` : `arrow-btn`}
                >
                  <FontAwesomeIcon icon={faChevronRight} />
                </button>
              </li>

              {currentPage != 1 && currentPage != 2 && (
                <li className="test-last" onClick={() => handleGoToPage(1)}>
                  <button>1</button>
                </li>
              )}
              {currentPage != 1 && currentPage != 2 && (
                <li className="to-continue">...</li>
              )}

              {pages}

              {totalPages != currentPage &&
                currentPage != Number(totalPages) - 1 && (
                  <li className="to-continue">...</li>
                )}
              {totalPages != currentPage &&
                currentPage != Number(totalPages) - 1 && (
                  <li
                    className="test-last"
                    onClick={() => handleGoToPage(totalPages)}
                  >
                    <button>{totalPages}</button>
                  </li>
                )}

              {/* {currentPage != } */}
              <li className="last-page">
                <button
                  onClick={handleNextPage}
                  className={
                    currentPage == totalPages ? `disabled-btn` : `arrow-btn`
                  }
                >
                  <FontAwesomeIcon icon={faChevronLeft} />
                </button>
              </li>
            </ul>
          ) : null}
        </Col>
      </Row>
    </>
  );
};

export default AuthorServicesProfile;
