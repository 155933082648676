import { faCheckCircle, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { NavLink } from "react-router-dom";
import { createData, getData } from "../../../Services/DataService";
import { withRouter } from "react-router";
import Loading from "../../Blocks/Loading";
import * as Yup from "yup";

import "./index.scss";
import { useFormik } from "formik";
import {AiFillCheckCircle} from "react-icons/ai";
const Register = (props) => {
  const [name, setName] = useState("");
  const [user_name, setUser_name] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [code, setCode] = useState();
  const [successSendCode, setSuccessSendCode] = useState(false);
  const [checkCode, setCheckCode] = useState("");
  const [codeSuccess, setCodeSuccess] = useState(false);
  const [counter, setCounter] = useState(60);
  const [counterDown, setCounterDown] = useState(60);
  const [userTypes, setUserTypes] = useState([]);
  const [user_type, setUser_type] = useState(4);
  const [password, setPassword] = useState("");
  const [password_confirmation, setPassword_confirmation] = useState("");
  const [acceptRules, setAcceptRules] = useState(false);
  const [loading, setLoading] = useState(true);
  const [alertErrors, setAlertErrors] = useState(false);
  const [sucessRegister, setSucessRegister] = useState(false);
  const [errorsLog, setErrorsLog] = useState({});

  useEffect(() => {
    getData(`api/mobile-code/${mobile}`).then(
        (response) => {
          const code = response.data.data.code;
          setCode(code);
        },
        (error) => {}
    );
  }, [mobile]);

  const handelChangeCode = (e) => {
    setCheckCode(!checkCode);
  };

  const handelSmsCode = (e) => {
    e.preventDefault();
    setErrorsLog({});
    setAlertErrors(false);
    {
      mobile === "" ? setSuccessSendCode(false) : setSuccessSendCode(true);
    }

    const formData = new FormData();
    formData.append("mobile", mobile);
    createData(`api/send-mobile-code`, formData).then(
        (response) => {
          setSuccessSendCode(true);
          let counter = 60;
          setCounter(counter);
          let interval = setInterval(() => {
            counter--;
            setCounterDown(counter);
            if (counter === 0) {
              clearInterval(interval);
              setSuccessSendCode(false);
            }
          }, 1000);
        },
        (error) => {
          if (error) {
            const errors = error.response.data;
            setErrorsLog(errors);
            setAlertErrors(true);
          }
        }
    );
  };

  const handelCheckCode = () => {
    checkCode == "0000" ? setCodeSuccess(true) : setCodeSuccess(false);
  };

  useEffect(() => {
    handelCheckCode();
  }, [checkCode]);

  useEffect(() => {
    getData(`api/user-types`).then((response) => {
      const data = response.data;
      const dataArr = Object.values(data);
      setUser_type(dataArr["0"]?.id);
      setUserTypes(dataArr);
      setLoading(false);
    });
  }, []);
  const handleAcceptRules = () => {
    setAcceptRules(!acceptRules);
  };

  const loopOnUserType =
    userTypes.length > 0 ? (
      userTypes?.map((item, index) => {
        return (
          <label className="radio-label" key={index}>
            {item?.name}
            <input
              type="radio"
              id={item.id}
              name="user_type"
              defaultChecked={index === 0 ? `checked` : null}
              onClick={(e) => setUser_type(item.id)}
            />
            <span className="checkmark"></span>
          </label>
        );
      })
    ) : (
      <div>لا يوجد انواع لعضويات حاليا</div>
    );

  // Validation form

  const handleSubmit = () => {
    // e.preventDefault();
    setErrorsLog({});
    setAlertErrors(false);
    const formData = new FormData();
    formData.append("name", name);
    formData.append("username", user_name);
    formData.append("email", email);
    formData.append("mobile", mobile);
    formData.append("password", password);
    formData.append("password_confirmation", password_confirmation);
    formData.append("type", user_type);
    createData(`api/create-account`, formData).then(
      (response) => {
        setSucessRegister(true);
        window.scrollTo({ top: 0, behavior: "smooth" });
        setTimeout(() => {
          props.history.push("/login");
        }, 5000);
      },
      (error) => {
        if (error) {
          const errors = error.response.data;
          setErrorsLog(errors);
          setAlertErrors(true);
          window.scrollTo({ top: 0, behavior: "smooth" });
        }
      }
    );
  };
  const SignupSchema = Yup.object().shape({
    name: Yup.string()
      .min(4, "يجب ان يكون الاسم اكثر من 4 حروف")
      .required("هذا الحقل مطلوب"),
    username: Yup.string()
      .min(4, "يجب ان يكون الاسم اكثر من 4 حروف")
      .required("هذا الحقل مطلوب"),
    email: Yup.string()
      .email("بريد إلكتروني خاطئ")
      .required("هذا الحقل مطلوب"),
    password: Yup.string()
      .min(8, "يجب ان تكون كلمه السر 8 حروف او اكثر..")
      .max(20, "يجب ان يكون كلمه السر اقل من 20 حروف")
      .required("هذا الحقل مطلوب"),
    password_confirmation: Yup.string()
      .min(
        8,
        "يجب أن تكون كلمه السر مطابقًه للحقل السابق وأن تكون أقل من 8 أحرف "
      )
      .required("هذا الحقل مطلوب"),
  });
  const formik = useFormik({
    initialValues: {
      name: "",
      username: "",
      email: "",
      password: "",
      password_confirmation: "",
    },
    validationSchema: SignupSchema,
    onSubmit: (values) => {
      if (values.password === values.password_confirmation) {
        setName(values.name);
        setUser_name(values.username);
        setEmail(values.email);
        setPassword(values.password);
        setPassword_confirmation(values.password_confirmation);
        handleSubmit();
      }
    },
  });

  return (
    <>
      <Helmet>
        <title>مستخدم جديد</title>
        <meta name="description" content="Register" />
      </Helmet>

      {loading === true ? (
        <Loading />
      ) : (
        <section className="Register-sec">
          <Container>
            <Row>
              <Col xs={12} lg={10} className="mx-auto">
                <div className="form-heading">
                  <h1>نرحب بك في منصة السوانى</h1>
                  <p> عالم جديد في التحول الرقمي</p>
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={12} lg={8} className="mx-auto">
                {alertErrors && (
                  <div className="errors-alert">
                    <FontAwesomeIcon
                      className="alert-icon"
                      icon={faInfoCircle}
                    ></FontAwesomeIcon>
                    <span>برجاء عدم ترك الحقوق المطلوبة فارغة</span>
                  </div>
                )}
                {sucessRegister && (
                  <div className="sucess-alert">
                    <FontAwesomeIcon
                      className="alert-icon"
                      icon={faCheckCircle}
                    ></FontAwesomeIcon>
                    <span>
                      شكرا لقد تم تسجيلك بنجاح و سيتم تحويلك لصفحة الدخول
                    </span>
                  </div>
                )}
                <Form className="RegisterForm" onSubmit={formik.handleSubmit}>
                  <Row>
                    <Col xs={12} lg={6}>
                      <Form.Group
                        className={`${
                          errorsLog && errorsLog.name ? "has-error" : ''
                        }`}
                      >
                        <Form.Label>الاسم كامل *</Form.Label>
                        <Form.Control
                          id="name"
                          onChange={formik.handleChange}
                          type="text"
                          placeholder="ادخل اسمك ثلاثي"
                          name="name"
                          value={formik.values.name}
                          onBlur={(e) =>
                            e.target.value.length > 4 && setName(e.target.value)
                          }
                        />
                        {errorsLog && errorsLog.name ? (
                          <div className="errors-parent">
                            <span className="error-txt">
                              {errorsLog.name?.ar}
                            </span>
                          </div>
                        ) : null}
                        {formik.touched.name && formik.errors.name ? (
                          <div className="text-danger">
                            {formik.errors.name}
                          </div>
                        ) : null}
                      </Form.Group>
                    </Col>
                    <Col xs={12} lg={6}>
                      <Form.Group
                        className={`${
                          errorsLog && errorsLog.username ? "has-error" : null
                        }`}
                      >
                        <Form.Label>اسم العضوية *</Form.Label>
                        <Form.Control
                          id="user_name"
                          onChange={formik.handleChange}
                          type="text"
                          placeholder="اسم المستعار لحسابك"
                          name="username"
                          value={formik.values.username}
                          onBlur={(e) =>
                            e.target.value.length > 4 &&
                            setUser_name(e.target.value)
                          }
                        />
                        {errorsLog && errorsLog.username ? (
                          <div className="errors-parent">
                            <span className="error-txt">
                              {errorsLog.username?.ar}
                            </span>
                          </div>
                        ) : null}
                        {formik.touched.username && formik.errors.username ? (
                          <div className="text-danger">
                            {formik.errors.username}
                          </div>
                        ) : null}
                      </Form.Group>
                    </Col>
                  </Row>
                  <Form.Group className="mb-3  mobile-wrap" id="formType">
                    <Form.Label>فئة العضوية </Form.Label>
                    <div className="radio-parent">{loopOnUserType}</div>
                  </Form.Group>

                  <Row>
                    <Col xs={12} lg={6}>
                      <Form.Group
                        className={`${
                          errorsLog && errorsLog.email ? "has-error" : null
                        }`}
                      >
                        <Form.Label>البريد الالكتروني *</Form.Label>
                        <Form.Control
                          id="email"
                          onChange={formik.handleChange}
                          type="email"
                          placeholder="name@domain.com"
                          name="email"
                          value={formik.values.email}
                          onBlur={(e) =>
                            /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(
                              e.target.value
                            ) && setEmail(e.target.value)
                          }
                        />
                        {errorsLog && errorsLog.email ? (
                          <div className="errors-parent">
                            <span className="error-txt">
                              {errorsLog.email?.ar}
                            </span>
                          </div>
                        ) : null}
                        {formik.touched.email && formik.errors.email ? (
                          <div className="text-danger">
                            {formik.errors.email}
                          </div>
                        ) : null}
                      </Form.Group>
                    </Col>
                    <Col xs={12} lg={6}>
                      <Form.Group
                          className={`${
                              errorsLog && errorsLog.mobile ? "has-error" : null
                          }`}
                      >
                        <Form.Label>رقم الجوال *</Form.Label>
                        <Form.Control
                            id="mobile"
                            onChange={(e) =>
                                e.target.value.length > 9 &&
                                setMobile(e.target.value)
                            }
                            type="number"
                            placeholder="رقم جوالك"
                        />

                        <div className="smsProvider">
                          <span
                              onClick={handelSmsCode}
                              className="sendCode"
                              title=""
                          >
                            {successSendCode === false ||
                            (errorsLog && errorsLog.mobile)
                                ? " أرسل كود التحقق"
                                : "اعادة ارسال"}
                          </span>
                          {successSendCode == false ||
                          (errorsLog && errorsLog.mobile) ? null : (
                              <>
                                <Form.Label>
                                  أدخل الكودالمرسل لجوالك *{" "}
                                  {codeSuccess === true ? null : (
                                      <span className="counterNotif">
                                    {" "}
                                        خلال {counterDown} ثانية
                                  </span>
                                  )}{" "}
                                </Form.Label>
                                <Form.Control
                                    id="checkCode"
                                    onChange={(e) => setCheckCode(e.target.value)}
                                    type="text"
                                    placeholder="الكود"
                                />
                                <span
                                    onClick={handelCheckCode}
                                    className="checkCode"
                                >
                                {codeSuccess === true
                                    ? "  كود صحيح"
                                    : codeSuccess === false &&
                                    errorsLog &&
                                    errorsLog.checkCode
                                        ? "اعادة ارسال"
                                        : "تحقق"}
                              </span>

                                {codeSuccess === true ? (
                                    <span
                                        className="rightCode"
                                        title="الكود صحيح يمكنك استكمال البيانات لتتمكن من تسجيل عضوية جديده"
                                    >
                                  {" "}
                                      الكود صحيح يمكنك استكمال البيانات لتتمكن من
                                  تسجيل عضوية جديده
                                  <AiFillCheckCircle />
                                </span>
                                ) : codeSuccess === false ? (
                                    <div className="has-error errors-parent">
                                  <span className="error-txt">
                                    {errorsLog.data}
                                  </span>
                                    </div>
                                ) : null}
                              </>
                          )}
                          {errorsLog && errorsLog.checkCode ? (
                              <div className="errors-parent">
                              <span className="error-txt">
                                {errorsLog.data}
                              </span>
                              </div>
                          ) : null}
                        </div>
                        {errorsLog && errorsLog.mobile ? (
                            <div className="errors-parent">
                            <span className="error-txt">
                              {errorsLog.mobile?.ar}
                            </span>
                            </div>
                        ) : null}
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={12} lg={6}>
                      <Form.Group
                        className={`${
                          errorsLog && errorsLog.password ? "has-error" : null
                        }`}
                      >
                        <Form.Label>كلمة المرور *</Form.Label>
                        <Form.Control
                          id="password"
                          type="password"
                          onChange={formik.handleChange}
                          onBlur={(e) => e.target.value.length >= 8 && setPassword(e.target.value)}
                          placeholder="كلمة المرور لا تقل على 8 ارقام وحروف"
                          name="password"
                          value={formik.values.password}
                        />
                        {errorsLog && errorsLog.password ? (
                          <div className="errors-parent">
                            <span className="error-txt">
                              {errorsLog.password?.ar}
                            </span>
                          </div>
                        ) : null}
                        {formik.touched.password && formik.errors.password ? (
                          <div className="text-danger">
                            {formik.errors.password}
                          </div>
                        ) : null}
                      </Form.Group>
                    </Col>
                    <Col xs={12} lg={6}>
                      <Form.Group
                        className={`${
                          errorsLog && errorsLog.password_confirmation
                            ? "has-error"
                            : null
                        }`}
                      >
                        <Form.Label>تأكيد كلمة المرور</Form.Label>
                        <Form.Control
                          id="password_confirmation"
                          onChange={formik.handleChange}
                          onBlur={(e) =>
                            e.target.value.length >= 8 && setPassword_confirmation(e.target.value)
                          }
                          type="password"
                          placeholder="كلمة المرور لا تقل على 8 ارقام وحروف"
                          name="password_confirmation"
                          value={formik.values.password_confirmation}
                        />
                        {errorsLog && errorsLog.password_confirmation ? (
                          <div className="errors-parent">
                            <span className="error-txt">
                              {errorsLog.password_confirmation?.ar}
                            </span>
                          </div>
                        ) : null}
                        {formik.touched.password_confirmation &&
                        formik.errors.password_confirmation ? (
                          <div className="text-danger">
                            {formik.errors.password_confirmation}
                          </div>
                        ) : null}
                        {password !== password_confirmation ? (
                          <div className="text-danger">
                            يجب أن تكون كلمه السر مطابقًه للحقل السابق وأن تكون
                            أقل من 8 أحرف{" "}
                          </div>
                        ) : null}
                      </Form.Group>
                    </Col>
                  </Row>
                  <Form.Group className="d-flex">
                    <Form.Check
                      id="checkRules"
                      type="checkbox"
                      onClick={handleAcceptRules}
                    />
                    <label htmlFor="checkRules" className="checkRules">
                      أوافق علي القوانين و الارشادات الخاصة بالموقع كما هو موضح
                      في صفحة{" "}
                      <NavLink
                        to="/terms-conditions"
                        target="_blank"
                        className="privacy-link"
                      >
                        الشروط والأحكام
                      </NavLink>
                      <span>{" & "}</span>
                      <NavLink
                        to="/privacy-policy"
                        target="_blank"
                        className="privacy-link"
                      >
                        سياسة الموقع
                      </NavLink>{" "}
                    </label>
                  </Form.Group>

                  <Row>
                    <Col xs={6} className="mx-auto">
                      <Button
                        className="btn primary-btn flx-centered"
                        type="submit"
                        disabled={
                          acceptRules === false || codeSuccess === false
                            ? `disabled`
                            : // ? ``
                              null
                        }
                      >
                        تسجيل عضوية
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          </Container>
        </section>
      )}
    </>
  );
};

export default withRouter(Register);
