import React, { useState, useEffect } from "react";
import { Container, Row, Col, Form } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { createData, getData } from "../../../Services/DataService";
import Loading from "../../../Components/Blocks/Loading";
import "./index.scss";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { Helmet } from "react-helmet";
import Cookies from "universal-cookie";
import ErrorPage from "../../NotFound/404Page";

const DealsAdd = () => {
  const [titleAr, setTitleAr] = useState("");
  const [contentAr, setContentAr] = useState("");
  const [dealsTypes, setDealsTypes] = useState([
    { id: 1, title: "عرض" },
    { id: 2, title: "طلب" },
  ]);
  const [dealType, setDealType] = useState(dealsTypes[0].id);
  const [dealImage, setDealImage] = useState([]);
  const [userPicName, setUserPicName] = useState("");
  const [dealsCategories, setDealsCategories] = useState([]);
  const [dealsCategory, setDealsCategory] = useState("");
  const [sucessSend, setSucessSend] = useState(false);
  const [errorsLog, setErrorsLog] = useState({});
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    getData(`api/services-category`).then(
      (response) => {
        const dealsCategories = response?.data?.data;
        setDealsCategories(dealsCategories);
        setDealsCategory(dealsCategories[0]?.id);
        setLoading(false);
      },
      (error) => {
        setDealsCategories([]);
        setLoading(false);
      }
    );
  }, []);

  const clearInputs = () => {
    setTitleAr("");
    setDealType(dealsTypes[0].id);
    setDealsCategory(dealsCategories[0]?.id);
    setDealImage([]);
    setUserPicName("");
    setContentAr("");
    setErrorsLog({});
  };

  const handleSubmit = (evt) => {
    evt.preventDefault();

    const formData = new FormData();
    formData.append("titleEn", titleAr);
    formData.append("titleAr", titleAr);
    formData.append("contentEn", contentAr);
    formData.append("contentAr", contentAr);

    const images = dealImage;
    for (var x = 0; x < images.length; x++) {
      formData.append("gallery[]", images[x]);
    }
    formData.append("type", dealType);
    formData.append("category", dealsCategory);

    createData(`api/add-services`, formData).then(
      (response) => {
        setSucessSend(true);
        clearInputs();
        window.scrollTo({ top: 0, behavior: "smooth" });
      },
      (error) => {
        if (error) {
          setSucessSend(false);
          const errors = error?.response?.data;
          setErrorsLog(errors);
          window.scrollTo({ top: 0, behavior: "smooth" });
        }
      }
    );

    // const headers = {
    //     "Content-Type": "multipart/form-data",
    //     "Accept":"application/json",
    //     "Authorization":`Bearer ${getCookie('token')}`
    // }

    // axios.post(`${BaseUrl}/api/add-services`, formData , {headers}).then(
    //     (response) => {
    //         setSucessSend(true)
    //     },
    //     (error) => {
    //         const errorsLog = error?.response?.data;
    //         setErrorsLog(errorsLog)
    //     }
    // );
  };
  const cookie = new Cookies();

  if (cookie.get("service") == 0) return <ErrorPage />;
  return (
    <>
      <Helmet>
        <title>إضافة صفقة جديدة</title>
      </Helmet>

      {/* <Sugar customLoading={loading} color={'#00A388'} background="#87D4C7"/> */}
      {loading && <Loading />}
      <section className="Deals-add">
        <Container>
          <Row>
            <Col xs={12}>
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <NavLink to="/" className="bredcrumb-link">
                    الرئيسية
                  </NavLink>
                </li>

                <li className="breadcrumb-item">
                  <NavLink
                    to="/deals"
                    className="bredcrumb-link mob-bredcrumb t-none"
                  >
                    صفقات البيع و الشراء
                  </NavLink>
                </li>

                <li className="breadcrumb-item">
                  <span className="mob-bredcrumb active">إنشاء صفقة جديدة</span>
                </li>
              </ul>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <div className="form-heading">
                <h1>إنشاء صفقة جديدة</h1>
              </div>
            </Col>
          </Row>
          <Row>
            <Col
              xs={6}
              className="mx-auto"
              style={{
                minWidth: "320px",
              }}
            >
              {sucessSend && (
                <div className="sucess-alert">
                  <FontAwesomeIcon
                    className="alert-icon"
                    icon={faCheckCircle}
                  ></FontAwesomeIcon>
                  <span>تم ارسال طلبك بنجاح</span>
                </div>
              )}
              <div className="offer-add-parent">
                <form className="apply-form FormParent" onSubmit={handleSubmit}>
                  <div
                    className={`form-group ${
                      errorsLog && errorsLog?.titleAr ? "has-error" : null
                    }`}
                  >
                    <label htmlFor="offerTitle">عنوان الصفقة</label>
                    <input
                      className="form-control"
                      type="text"
                      value={titleAr}
                      id="titleAr"
                      placeholder="ادخل عنوان للعرض"
                      onChange={(e) => setTitleAr(e.target.value)}
                      name="titleAr"
                    />
                    {errorsLog && errorsLog?.titleAr?.ar ? (
                      <div className="errors-parent">
                        <span className="error-txt">
                          {errorsLog?.titleAr?.ar}
                        </span>
                      </div>
                    ) : null}
                  </div>

                  <div
                    className={`form-group ${
                      errorsLog && errorsLog?.type ? "has-error" : null
                    }`}
                  >
                    <label htmlFor="offerTitle">نوع الصفقة</label>
                    <select
                      onChange={(e) =>
                        setDealType(e.target[e.target.selectedIndex].id)
                      }
                    >
                      {dealsTypes?.map((type) => {
                        return (
                          <>
                            <option id={type?.id} key={type.id} value={type.id}>
                              {type.title}
                            </option>
                          </>
                        );
                      })}
                    </select>
                    {errorsLog && errorsLog?.type?.ar ? (
                      <div className="errors-parent">
                        <span className="error-txt">{errorsLog?.type?.ar}</span>
                      </div>
                    ) : null}
                  </div>
                  <div
                    className={`form-group ${
                      errorsLog && errorsLog?.category ? "has-error" : null
                    }`}
                  >
                    <label htmlFor="dealsCategory">التصنيفات</label>
                    <select
                      onChange={(e) =>
                        setDealsCategory(e.target[e.target.selectedIndex].id)
                      }
                    >
                      {dealsCategories?.map((cat) => {
                        return (
                          <>
                            {
                              <option
                                id={cat?.id}
                                key={cat?.id}
                                value={cat?.title?.ar}
                              >
                                {cat?.title?.ar}
                              </option>
                            }
                          </>
                        );
                      })}
                    </select>
                    {errorsLog && errorsLog?.category?.ar ? (
                      <div className="errors-parent">
                        <span className="error-txt">
                          {errorsLog?.category?.ar}
                        </span>
                      </div>
                    ) : null}
                  </div>

                  <Form.Group
                    className={`${
                      errorsLog && errorsLog?.gallery ? "has-error" : null
                    }`}
                  >
                    <Form.Label>صورة الصفقة</Form.Label>
                    <div
                      className="custom-input-file-parent"
                      onChange={(e) => setUserPicName(e.target.value)}
                    >
                      <Form.Control
                        type="file"
                        multiple
                        onChange={(e) => setDealImage(e.target.files)}
                        id="dealImage"
                      />
                      <div className="custom-input-file">
                        <span className="changable-txt">
                          {userPicName !== ""
                            ? userPicName
                            : `حجم الملف لا يزيد عن 5 ميجا بايت`}
                        </span>
                        <span className="colored-txt">آختر صورة</span>
                      </div>
                    </div>
                    {errorsLog && errorsLog?.gallery?.ar ? (
                      <div className="errors-parent">
                        <span className="error-txt">
                          {errorsLog?.gallery?.ar}
                        </span>
                      </div>
                    ) : null}
                  </Form.Group>

                  <div
                    className={`form-group ${
                      errorsLog && errorsLog?.contentAr ? "has-error" : null
                    }`}
                  >
                    <label htmlFor="offerDetails">تفاصيل العرض</label>
                    <CKEditor
                      UploadAdapter={true}
                      config={{
                        toolbar: {
                          items: ["heading", "bold", "italic", "link"],
                        },
                      }}
                      //  ImageUploadUI={true}
                      editor={ClassicEditor}
                      data=""
                      onReady={(editor) => {
                        // You can store the "editor" and use when it is needed.
                        // console.log("Editor is ready to use!", editor);
                      }}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        setContentAr(data);
                      }}
                      editorLoaded={true}
                    />
                    {/* <textarea id="contentAr" className="form-control form-msg" value={contentAr} name='contentAr' placeholder="اكتب تفاصيل الصفقة هنا" onChange={(e) => setContentAr(e.target.value)}></textarea> */}
                    {errorsLog && errorsLog?.contentAr?.ar ? (
                      <div className="errors-parent">
                        <span className="error-txt">
                          {errorsLog?.contentAr?.ar}
                        </span>
                      </div>
                    ) : null}
                  </div>
                  <button className="btn primary-btn mt-3"> ابدأ الصفقة</button>
                </form>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default DealsAdd;
