import {
  faCheckCircle,
  faClock,
  faComments,
  faExclamationTriangle,
  faHeart,
  faMapMarkerAlt,
  faStar,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import { AiFillCheckCircle } from "react-icons/ai";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import { getCookie } from "../../../Components/Auth/ForCookie";
import RatingBlock from "../../../Components/Blocks/Rating";
import { createData, getData } from "../../../Services/DataService";
import SendMessageCat from "../../Chats/SendMessageCat";
import Loading from "../../../Components/Blocks/Loading";
import Favourite from "../../../Components/Blocks/Favourite";
import "./index.scss";
import useScrollToTop from "../../../hooks/useScrollToTop";
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";

const BookShow = (props) => {
  const [loading, setLoading] = useState(true);
  const [bookData, setBookData] = useState([]);
  const [bookImages, setBookImages] = useState([]);
  const [authorBooks, setAuthorBooks] = useState([]);
  const [bookAuthorId, setBookAuthorId] = useState("");
  const [main_category, setMain_category] = useState("");
  const [main_category_id, setMain_category_id] = useState("");
  const [bookName, setBookName] = useState("");
  const [bookDate, setBookDate] = useState("");
  const [bookstatus, setBookstatus] = useState("");
  const [booAuthor, setBooAuthor] = useState("");
  const [bookCity, setBookCity] = useState("");
  const [bookPublishHouse, setBookPublishHouse] = useState("");
  const [bookLanguage, setBookLanguage] = useState("");
  const [bookPrintYear, setBookPrintYear] = useState("");
  const [bookPageNumber, setBookPageNumber] = useState("");
  const [bookQty, setBookQty] = useState("");
  const [bookIsbin, setBookIsbin] = useState("");
  const [bookContent, setBookContent] = useState("");
  const [bookPrice, setBookPrice] = useState("");
  const [bookAuthor, setBookAuthor] = useState("");
  const [bookAuthorImage, setBookAuthorImage] = useState("");
  const [bookAuthorCountry, setBookAuthorCountry] = useState("");
  const [bookAuthorCity, setBookAuthorCity] = useState("");
  const [bookRateNumber, setBookRateNumber] = useState("");
  const [loginedUserRate, setLoginedUserRate] = useState("");
  const [bookAuthorRates, setBookAuthorRates] = useState("");
  const [bookAuthorType, setBookAuthorType] = useState("");
  const [openSendMessage, setOpenSendMessage] = useState(false);
  const [openRejectMessage, setOpenRejectMessage] = useState(false);
  const [sponsoredBook, setSponsoredBook] = useState(0);
  const [rejectedMessage, setRejectedMessage] = useState("");
  const [sucessSendResone, setSucessSendResone] = useState(false);
  const [errorsLog, setErrorsLog] = useState({});
  const [alertErrors, setAlertErrors] = useState(false);
  const [token, setToken] = useState(`Bearer ${getCookie("token")}`);
  const [verified, setVerified] = useState("");
  const [wishlist, setWishlist] = useState(null);
  const [type, setType] = useState("Books");
  const [userDataId, setUserDataId] = useState(null);

  useEffect(() => {
    getData(`api/books/${props.match.params.id}`).then(
      (response) => {
        const bookData = response?.data?.data;
        setBookData(bookData);
        setMain_category(bookData.main_category.title.ar);
        setMain_category_id(bookData.main_category.id);
        setBookName(bookData.title.ar);
        setBookDate(bookData.created_at);
        setBookImages(bookData.images);
        setBookstatus(bookData.used_status.ar);
        setBooAuthor(bookData.author_id);
        setBookCity(bookData.buy_city.ar);
        setBookPublishHouse(bookData.publish_house_id);
        setBookLanguage(bookData.book_language.title);
        setBookPrintYear(bookData.print_year);
        setBookPageNumber(bookData.page_no);
        setBookQty(bookData.qty);
        setBookIsbin(bookData.isbn);
        setBookContent(bookData.content.ar);
        setBookPrice(bookData.price);
        setBookAuthor(bookData.user_id.name);
        setBookAuthorImage(bookData.user_id.avatar);
        setBookAuthorCountry(bookData?.user_id?.city?.ar);
        setUserDataId(bookData.user_id.id);
        setBookAuthorCity(bookData?.user_id?.city_id?.title?.ar);
        setBookAuthorId(bookData.user_id.id);
        setVerified(bookData.user_id.verified);
        setLoginedUserRate(bookData.book_user_rate);
        setBookRateNumber(bookData.rates);
        setBookAuthorRates(bookData.user_rate);
        setBookAuthorType(bookData.user_type.ar);
        setSponsoredBook(bookData.is_sponsored);
        setWishlist(bookData.check_wishlist);
        setLoading(false);
      },
      (error) => {
        setBookData([]);
        // window.location.href = "/404";
      }
    );
  }, [props.match.params.id]);
  useEffect(() => {
    getData(`api/author/books/${props.match.params.id}`).then(
      (response) => {
        const authorBooks = response.data.data.data;
        setAuthorBooks(authorBooks);
      },
      (error) => {
        setAuthorBooks([]);
      }
    );
  }, [props.match.params.id]);
  useScrollToTop();
  const handleChange = (e) => {
    setRejectedMessage(e.target.value);

  };
  const handleShowSendMessage = (e) => {
    setOpenSendMessage(!openSendMessage);
  };
  const handleShowSendRejectedMessage = (e) => {
    setOpenRejectMessage(!openRejectMessage);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setErrorsLog({});
    setAlertErrors(false);

    const formData = new FormData();
    formData.append("book_id", props.match.params.id);
    formData.append("report_comment", rejectedMessage);
    createData(`api/report-book`, formData).then(
      (response) => {
        setSucessSendResone(true);
        setOpenRejectMessage(false);
        window.scrollTo({ top: 0, behavior: "smooth" });
      },
      (error) => {
        if (error) {
          const errors = error?.response?.data;
          setErrorsLog(errors);
          setAlertErrors(true);
          window.scrollTo({ top: 0, behavior: "smooth" });
        }
      }
    );
  };

  const settings_imgs = {
    dots: true,
    touchMove: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    rtl: true,
  };
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: Number(authorBooks.length) >= 3 ? 3 : 1,
    slidesToScroll: 1,
    arrows: true,
    rtl: false,
    responsive: [
      {
        breakpoint: 922,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 580,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
          arrows: false,
        },
      },
    ],
  };
  const settings2 = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    rtl: true,
    touchMove: false,
    responsive: [
      {
        breakpoint: 922,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 580,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
          arrows: false,
        },
      },
    ],
  };

  // this function will send virfy request to the user
  const handleSendVerifyRequest = (e) => {
    const formData = new FormData();
    formData.append("user_id", getCookie("userId"));
    formData.append("follow_id", e);

    createData(`api/verify-request/${userDataId}`, formData).then(
      (res) => {
        const verified = res.data.data;
        setVerified(verified);
      },
      (err) => {
        setVerified("");
      }
    );
  };
  const getBooksRate = (e) => {
    e > 0 && setBookRateNumber(e);
  };

  return (
    <>
      <Helmet>
        <title>{bookName || "تفاصيل الكتاب"}</title>
        <meta name="description" content="Details Book" />
      </Helmet>
      {loading === true ? (
        <Loading />
      ) : (
        <>
          <Container>
            <Row>
              <Col xs={12}>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="/" className="bredcrumb-link">
                      الرئيسية
                    </a>
                  </li>

                  <li className="breadcrumb-item t-none">
                    <Link
                      to={`/books/categories/${main_category_id &&
                        main_category_id}`}
                      className="mob-bredcrumb t-none"
                    >
                      {main_category && main_category}
                    </Link>
                  </li>

                  <li className="breadcrumb-item">
                    <span className="mob-bredcrumb active">
                      {bookName && bookName}
                    </span>
                  </li>
                </ul>
              </Col>
              <Col lg={12}>
                {sucessSendResone && (
                  <div className="sucess-alert">
                    <FontAwesomeIcon
                      className="alert-icon"
                      icon={faCheckCircle}
                    ></FontAwesomeIcon>
                    <span>شكرا لك تم ارسال طلبك بنجاح </span>
                  </div>
                )}
              </Col>
            </Row>
          </Container>
          <section className="book-page-parent">
            <Container className="book-parent">
              {sponsoredBook === 1 ? (
                <div className="Sponser-book">
                  <span>إعلان مميز</span>
                </div>
              ) : null}
              <Row>
                <Col xs={12} lg={8}>
                  <div className="book-title">
                    <h2
                      dangerouslySetInnerHTML={{
                        __html: bookName && bookName,
                      }}
                    ></h2>
                  </div>
                  <div className="date-rate">
                    <div className="date">
                      <FontAwesomeIcon icon={faClock} />
                      <span>تم نشر الإعلان في :{bookDate && bookDate}</span>
                    </div>

                    <div className="rate d-flex align-items-center">
                      {console.log(
                        "bookRateNumberbookRateNumber",
                        bookRateNumber
                      )}
                      {!bookData?.same_user && (
                        <>
                          {getCookie("loginned") && loginedUserRate == 0 ? (
                            // {(getCookie("loginned")) && (
                            <RatingBlock
                              bookRate={true}
                              bookId={props.match.params.id}
                              getBooksRate={getBooksRate}
                              type="book"
                            />
                          ) : (
                            <RatingBlock
                              initialRating={Math.round(loginedUserRate)}
                              // readonly={true}
                              bookRate={true}
                              bookId={props.match.params.id}
                              getBooksRate={getBooksRate}
                              type="book"
                            />
                          )}
                        </>
                      )}

                      <div className="rates mx-3">
                        <span>{bookRateNumber}</span>
                        <FontAwesomeIcon icon={faStar} className="rate-icon" />
                      </div>
                      {/* {bookAuthorId != getCookie("userId") && ( */}
                      <Favourite
                        bookId={props.match.params.id}
                        isFavourite={`${wishlist === 1 ? true : false}`}
                      />
                      {/* )} */}
                    </div>
                  </div>
                  <div className="book-image">
                    {bookImages.length > 0 ? (
                      <div className="images-slider-parent">
                        <Slider {...settings_imgs}>
                          {bookImages.map((img) => {
                            return (
                              <LazyLoadImage
                                key={Math.random()}
                                src={img}
                                alt="image"
                              />
                            );
                          })}
                        </Slider>
                      </div>
                    ) : null}
                  </div>

                  <div className="book-info">
                    <div className="card">
                      <p className="info-name">حالة الكتاب : </p>
                      <span>{bookstatus && bookstatus}</span>
                    </div>
                    <div className="card">
                      <p className="info-name"> الكاتب / المؤلف :</p>
                      <span>{booAuthor && booAuthor}</span>
                    </div>
                    <div className="card">
                      <p className="info-name"> مدينة البيع :</p>
                      <span>{bookCity && bookCity}</span>
                    </div>
                    <div className="card">
                      <p className="info-name"> دار النشر :</p>
                      <span>{bookPublishHouse}</span>
                    </div>
                    <div className="card">
                      <p className="info-name"> اللغة:</p>
                      <span>{bookLanguage && bookLanguage}</span>
                    </div>
                    <div className="card">
                      <p className="info-name"> سنة الطباعة :</p>
                      <span>{bookPrintYear && bookPrintYear}</span>
                    </div>
                    <div className="card">
                      <p className="info-name"> عدد الصفحات :</p>
                      <span>{bookPageNumber && bookPageNumber}</span>
                    </div>
                    <div className="card">
                      <p className="info-name"> كمية الكتب:</p>
                      <span>{bookQty && bookQty}</span>
                    </div>
                    <div className="card">
                      <p className="info-name"> ISBN code :</p>
                      <span>{bookIsbin && bookIsbin}</span>
                    </div>
                  </div>

                  <div className="book-content">
                    <p
                      dangerouslySetInnerHTML={{
                        __html: bookContent && bookContent,
                      }}
                    ></p>
                  </div>
                </Col>
                <Col xs={12} lg={4}>
                  <div className="book-price">
                    <p className="number">{bookPrice && bookPrice}</p>
                    <span className="currency">ريال سعودي</span>
                  </div>

                  {getCookie("loginned") ? (
                    <>
                      {bookAuthorId === getCookie("userId") ? null : getCookie(
                          "message"
                        ) == "1" ? (
                        <div className="message-author">
                          <div
                            className="message"
                            onClick={handleShowSendMessage}
                          >
                            <FontAwesomeIcon icon={faComments} />
                            <p>مراسلة المعلن</p>
                          </div>

                          {/* <div className="favorite desktop-hide">
                            <FontAwesomeIcon icon={faHeart} />
                          </div> */}
                        </div>
                      ) : null}
                    </>
                  ) : (
                    <>
                      <div className="message-author">
                        <div
                          className="message"
                          onClick={() => {
                            toast.error(
                              "يلزم تسجيل الدخول لتتمكن من ارسال رسالة للمعلن ",
                              {
                                toastId: 401,
                              }
                            );
                          }}
                          // disabled={getCookie("loginned") ? null : `disabled`}
                          title="يلزم تسجيل الدخول لتتمكن من ارسال رسالة للمعلن "
                        >
                          <FontAwesomeIcon icon={faComments} />
                          <p>مراسلة المعلن</p>
                        </div>

                        <div className="favorite desktop-hide">
                          <FontAwesomeIcon icon={faHeart} />
                        </div>
                      </div>
                    </>
                  )}

                  <div className="author-sec flx-centered">
                    <div className="cont flx-centered">
                      <div className="cont-img">
                        <a href={`/profile/${bookAuthorId}`}>
                          <LazyLoadImage src={bookAuthorImage} alt="" />
                        </a>
                      </div>
                      <div className="cont-txt">
                        <h3>
                          <a href={`/profile/${bookAuthorId}`}>{bookAuthor} </a>

                          {(() => {
                            if (verified == 1) {
                              return (
                                <>
                                  {getCookie("loginned") ? (
                                    <span className="PendingVrf">
                                      فى انتظار الموافقه على طلب التوثيق
                                    </span>
                                  ) : (
                                    <span className="rejectedVrf">
                                      غير موثق
                                    </span>
                                  )}
                                </>
                              );
                            } else if (verified == 2) {
                              return (
                                <span>
                                  <AiFillCheckCircle />
                                </span>
                              );
                            } else if (verified == 3) {
                              return (
                                <>
                                  {getCookie("loginned") ? (
                                    <span className="rejectedVrf">
                                      X تم رفض طلب التوثيق
                                    </span>
                                  ) : (
                                    <span className="rejectedVrf">
                                      غير موثق
                                    </span>
                                  )}
                                </>
                              );
                            } else {
                              return (
                                <span className="rejectedVrf">غير موثق</span>
                              );
                            }
                          })()}
                        </h3>
                        <div className="author-info mb-2 flx-centered">
                          {bookAuthorType === null ? null : (
                            <span className="author-type">
                              {bookAuthorType}
                            </span>
                          )}

                          <span className="author-rate">
                            {bookAuthorRates}
                            <FontAwesomeIcon
                              icon={faStar}
                              className="rate-icon"
                            />
                          </span>
                        </div>
                        <p className="author-location">
                          <FontAwesomeIcon
                            icon={faMapMarkerAlt}
                            className="rate-icon"
                          />
                          {bookAuthorCity}
                          {bookAuthorCountry}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="instructions">
                    <h2>
                      <FontAwesomeIcon
                        className="safety-icon"
                        icon={faExclamationTriangle}
                      />
                      إرشادات لسلامتك في حال مقابلة المعلن
                    </h2>
                    <p className="instruction">
                      <span className="number">1-</span>
                      يرجي مقابلة المعلن في الأماكن العامة
                    </p>
                    <p className="instruction">
                      <span className="number">2-</span>
                      من الأفضل اصطحاب شخص معك اثناء مقابلة المعلن
                    </p>
                    <p className="instruction">
                      <span className="number">3-</span>
                      لا تقوم بدفع او تحويل ثمن الكتاب قبل معاينته
                    </p>
                  </div>
                  {openRejectMessage ? (
                    <div className="alret-parent send-message-parent">
                      <div
                        className="close-alert"
                        onClick={handleShowSendRejectedMessage}
                      ></div>
                      <Form className="AddBookRejcted " onSubmit={handleSubmit}>
                        <Form.Group
                          className={`${
                            errorsLog && errorsLog.message ? "has-error" : null
                          }`}
                        >
                          <Form.Label>الرسالة</Form.Label>
                          <Form.Control
                            id="rejectedMessage"
                            onChange={handleChange}
                            value={rejectedMessage}
                            as="textarea"
                            placeholder="ادخل السبب"
                            required
                          />
                          {errorsLog && errorsLog.message ? (
                            <div className="errors-parent">
                              <span className="error-txt">
                                {errorsLog.message.ar}
                              </span>
                            </div>
                          ) : null}
                        </Form.Group>

                        <button
                          type="submit"
                          className="btn primary-btn btn btn-primary"
                        >
                          إرسال
                        </button>
                      </Form>
                    </div>
                  ) : null}

                  <button
                    className="rejectedContent"
                    onClick={handleShowSendRejectedMessage}
                    disabled={getCookie("loginned") ? null : `disabled`}
                    title={
                      getCookie("loginned")
                        ? null
                        : "يلزم تسجيل الدخول لتتمكن من الابلاغ عن محتوى مخالف"
                    }
                  >
                    ابلاغ عن محتوي مخالف <span>!</span>
                  </button>
                </Col>
              </Row>
            </Container>
            <section className="Featured-books-sec">
              <Container>
                <Row>
                  <Col xs={12}>
                    <div className="widget-title">
                      <h2>كتب اخري لنفس المعلن</h2>
                    </div>
                  </Col>

                  <Col>
                    {authorBooks.length ? (
                      <Slider {...settings} className="featured-slider">
                        {authorBooks.length > 0
                          ? authorBooks.map((item) => {
                              return (
                                <div key={item.id} className="featured-slide">
                                  <div className="cont">
                                    <div className="cont-img">
                                      <Slider {...settings2}>
                                        {item.images.map((img, index) => {
                                          return (
                                            <div key={index}>
                                              <a href={`/books/${item.id}`}>
                                                <LazyLoadImage src={img} />
                                              </a>
                                            </div>
                                          );
                                        })}
                                      </Slider>
                                    </div>
                                    <div className="cont-txt">
                                      <span className="cat-name">
                                        {item.main_category.title.ar}
                                      </span>
                                      <div className="rating">
                                        <span>{item.rates}</span>
                                        <FontAwesomeIcon
                                          icon={faStar}
                                          className="register-icon"
                                        />
                                      </div>
                                      <h3>
                                        <a href={`/books/${item.id}`}>
                                          {item.title.ar.length > 20 ? (
                                            <>
                                              {item.title.ar
                                                .slice(0, 20)
                                                .concat("...")}
                                            </>
                                          ) : (
                                            <>{item.title.ar}</>
                                          )}
                                        </a>
                                        <Favourite
                                          bookId={item.id}
                                          isFavourite={`${
                                            item.check_wishlist === 1
                                              ? true
                                              : false
                                          }`}
                                        />
                                      </h3>
                                      {item.content.ar.length > 50 ? (
                                        <div
                                          className="book-desc"
                                          dangerouslySetInnerHTML={{
                                            __html: item.content.ar
                                              .slice(0, 50)
                                              .concat("..."),
                                          }}
                                        ></div>
                                      ) : (
                                        <p
                                          className="book-desc"
                                          dangerouslySetInnerHTML={{
                                            __html: item.content.ar,
                                          }}
                                        ></p>
                                      )}
                                      <div className="price-info flx-centered">
                                        <p className="price">
                                          <span>{item.price}</span>ريال سعودي
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            })
                          : null}
                      </Slider>
                    ) : (
                      <p> لا توجد كتب مميزة لهذا الكاتب</p>
                    )}
                  </Col>
                </Row>
              </Container>
            </section>
          </section>

          {openSendMessage ? (
            <div className="alret-parent send-message-parent">
              <div
                className="close-alert"
                onClick={handleShowSendMessage}
              ></div>
              <div className="cont">
                <div className="heading">
                  <h2>إرسال رسالة للمعلن</h2>
                </div>
                <SendMessageCat
                  recevierId={bookAuthorId}
                  type={type}
                  CatID={props.match.params.id}
                />
              </div>
            </div>
          ) : null}
        </>
      )}
    </>
  );
};

export default BookShow;
