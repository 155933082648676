import React, { Component } from 'react';
import {  Container , Row, Col } from 'react-bootstrap';
import Slider from "react-slick";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faClock  } from '@fortawesome/free-solid-svg-icons';
import './NewsRelated.scss';
import axios from 'axios';
import {NavLink} from 'react-router-dom';
import BaseUrl from '../../../config';
import { useEffect } from 'react';
import { getData } from '../../../Services/DataService';
import { useState } from 'react';

const NewsRelated = ({pageName}) => {
    const [bLogNews,setBLogNews] = useState([]);
    const [loading,setLoading] = useState(true);

    useEffect(() => {
        getData(`api/blogs`).then(
            (response) => {
                const data = response.data.data.data;
                setBLogNews(data);
                setLoading(false);
            }, (error) => {
                setBLogNews([]);
                setLoading(false);
            }
        )
    },[])
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: Number(bLogNews?.length ) >= 2 ? 2:1,
        slidesToScroll: 2,
        arrows:true,
        rtl:true,
        touchMove:true,
        responsive: [
            {
                breakpoint: 922,
                settings: {
                    slidesToShow: Number(bLogNews?.length ) >= 2 ? 2:1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false
                }
                },
                {
                breakpoint: 768,
                settings: {
                    slidesToShow: Number(bLogNews?.length ) >= 2 ? 2:1,
                    slidesToScroll: 1,
                    dots: true,
                    arrows:false
                }
                },
                {
                breakpoint: 580,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: true,
                    arrows:false
                }
                }
            ]

    };
    return (
        <>
            <section className="related-blog-sec">
                <Container>
                    <Row>
                        <Col>
                            <div className="text-center m-b-40">
                                <h2 className="news-title">اخبار مشابهة</h2>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Slider {...settings} className="featured-slider">
                                {bLogNews.length>0?bLogNews.map(item => {
                                    return(
                                        <div key={item.id} className="blog-post-parent">
                                            <div className="cont">
                                                <div className="cont-img">
                                                    <img src={item.blog_img} alt=""/>
                                                </div>
                                                <div className="cont-txt">
                                                    <div className="date">
                                                        <FontAwesomeIcon  icon={faClock} className="register-icon"/>
                                                        <span>{item.created_at}</span>
                                                    </div>
                                                    <h2>
                                                        <NavLink to={`/${pageName && pageName}/${item.id}`}>{item.title.ar} </NavLink>
                                                    </h2>
                                                    {
                                                        item.content.ar?.length > 150 ?
                                                        <div className="desc" dangerouslySetInnerHTML={{ __html: `${item.content.ar.slice(0, 150)}...` }}></div>
                                                        :
                                                        <div className="desc" dangerouslySetInnerHTML={{ __html: item.content.ar }}></div>
                                                    }

                                                    <NavLink to={`/${pageName && pageName}/${item.id}`} className="btn primary-btn">اكمل القراءة</NavLink>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                }):null
                                }
                            </Slider>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
}

export default NewsRelated;