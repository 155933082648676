import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Container } from "react-bootstrap";
import axios from "axios";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import noChats from "../../../Assets/Img/nochats.png";
import { getCookie } from "../../../Components/Auth/ForCookie";
import BaseUrl from "../../../config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import validUrl from "valid-url";
import {
  faUser,
  faPaperPlane,
  faClock,
} from "@fortawesome/free-solid-svg-icons";
import { Link, NavLink, useHistory, useLocation } from "react-router-dom";
import Loading from "../../../Components/Blocks/Loading";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "./index.scss";
import { Helmet } from "react-helmet";

const MessagesList = () => {
  const history = useHistory();
  const location = useLocation();
  const idParams = new URLSearchParams(location.search)?.get("chat");

  const [loading, setLoading] = useState(true);
  const [noMessagesList, setNoMessagesList] = useState(false);
  const [noChatsOpened, setNoChatsOpened] = useState(true);
  const [currentchat, setCurrentChat] = useState(idParams || 0);
  const [messagesList, setMessagesList] = useState([]);
  const [openedChatContent, setOpenedChatContent] = useState([]);
  const [sendingMessageContent, setSendingMessageContent] = useState("");
  const [errorsLog, setErrorsLog] = useState("");
  const [galleryImg, setGalleryImg] = useState("");
  const [chatImages, setChatImages] = useState([]);
  const [inputFileName, setInputFileName] = useState("");
  const [unread, setUnread] = useState("");
  const [alertErrors, setAlertErrors] = useState(false);
  const [messageToSend, setMessageToSend] = useState("");
  const [tabType, setTabType] = useState("General");

  const handleChange = (e) => {
    setMessageToSend(e?.target?.value);
    setSendingMessageContent(e?.target?.value);
  };

  const chatContainer = useRef(null);

  const handleChangeMessagesSubject = async (e) => {
    // setMessagesList(null);
    // setOpenedChatContent("");
    setNoChatsOpened(true);

    const headers = {
      Accept: "*/*",
      Authorization: `Bearer ${getCookie("token")}`,
    };

    if (e === "General") {
      await axios.get(`${BaseUrl}/api/message-list/${e}`, { headers }).then(
        (response) => {
          setMessagesList(response.data.data);
          setUnread(response?.data?.un_read);
        },
        (error) => {
          setMessagesList("");
        }
      );
    } else {
      await axios
        .get(`${BaseUrl}/api/message-module-list/${e}`, { headers })
        .then(
          (response) => {
            setMessagesList(response.data.data);
          },
          (error) => {
            setMessagesList("");
          }
        );
    }
  };
  const handleChangeTab = (e) => {
    history.push(`/messages?tab=${e}`);
  };

  // change the tab type depending on the url
  const [chatId, setChatId] = useState("");
  const params = new URLSearchParams(location.search);
  const newChatId = params?.get("chat");
  const tab = params?.get("tab");
  useEffect(() => {
    handleChangeMessagesSubject(tab || "General");
  }, [tab]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const newChatId = params?.get("chat");
    const tab = params.get("tab");
    setChatId(newChatId);
    setTabType(tab);
    getChatData(newChatId, tab);
  }, [location]);

  const getChatData = async (chatId, tabType) => {
    const headers = {
      Accept: "*/*",
      Authorization: `Bearer ${getCookie("token")}`,
    };

    if (tabType === "General") {
      await axios
        .get(`${BaseUrl}/api/open-chat/${chatId}`, { headers })
        .then((response) => {
          setOpenedChatContent(response.data);
          setNoChatsOpened(false);
          scrollToMyRef();
          setCurrentChat(chatId);
        })
        .catch((error) => {
          setOpenedChatContent([]);
          setNoChatsOpened(true);
        })

        .finally(() => {
          setLoading(false);
        });
    } else {
      await axios
        .get(`${BaseUrl}/api/open-chat-module/${chatId}`, { headers })
        .then((response) => {
          setOpenedChatContent(response.data);
          setNoChatsOpened(false);
          scrollToMyRef();
          setCurrentChat(chatId);
        })
        .catch((error) => {
          setOpenedChatContent([]);
          setNoChatsOpened(true);
        })

        .finally(() => {
          setLoading(false);
        });
    }
  };

  const handleOpenChat = (e, type) => {
    history.push(`/messages?tab=${type}&chat=${e}`);
  };

  const handleImageUpload = (e, type) => {
    let uploadFileText = e.target.value;
    let fileSrc = e.target.files;
    setInputFileName(fileSrc[0].name);
    setGalleryImg(uploadFileText);
    setChatImages(fileSrc);
    // setState({ [e.target.id]: fileSrc });
  };

  const scrollToMyRef = () => {
    //   const scroll =
    //     Number(chatContainer?.current?.scrollHeight) -
    //     Number(chatContainer?.current?.clientHeight);
    //   chatContainer?.current?.scrollTo(0, scroll);
  };

  const handleSendNewMssage = (e, type) => {
    e.preventDefault();
    setErrorsLog({});
    setAlertErrors(false);
    const formData = new FormData();
    formData.append("receive", openedChatContent?.chat.send.id);
    formData.append("chat_id", openedChatContent?.chat.id);
    formData.append("message", sendingMessageContent);
    const images = chatImages;
    for (var x = 0; x < images.length; x++) {
      formData.append("image", images[x]);
    }

    const headers = {
      "Content-Type": "multipart/form-data",
      Accept: "application/json",
      Authorization: `Bearer ${getCookie("token")}`,
    };

    if (type === "General") {
      axios.post(`${BaseUrl}/api/send-message`, formData, { headers }).then(
        (response) => {
          setSendingMessageContent("");
          handleOpenChat(openedChatContent?.chat.id, "General");
          setChatImages([]);
          setErrorsLog("");
          setCurrentChat(0);
          setGalleryImg("");
          setAlertErrors(false);
          scrollToMyRef();
        },
        (error) => {
          if (error) {
            const errors = error.response.data;
            setErrorsLog(errors);
            setAlertErrors(true);
          }
        }
      );
    } else {
      axios
        .post(`${BaseUrl}/api/send-module-message`, formData, { headers })
        .then(
          (response) => {
            setSendingMessageContent("");
            handleOpenChat(openedChatContent?.chat.id, type);
            setChatImages([]);
            setErrorsLog("");
            setCurrentChat(0);
            setGalleryImg("");
            setAlertErrors(false);
            setTimeout(() => {
              scrollToMyRef();
            }, 100);
          },
          (error) => {
            if (error) {
              const errors = error.response.data;
              setErrorsLog(errors);
              setAlertErrors(true);
            }
          }
        );
    }
  };

  return (
    <>
      <Helmet>
        <title>الرسائل</title>
      </Helmet>
      {loading === true ? (
        <Loading />
      ) : (
        <section className="messages-list-page-parent">
          <Container>
            {/* line for breadcrumb to show */}
            <Row>
              <Col xs={12}>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <NavLink to="/" className="bredcrumb-link">
                      الرئيسية
                    </NavLink>
                  </li>
                  <li className="breadcrumb-item">
                    <span className="mob-bredcrumb active">رسائلي</span>
                  </li>
                </ul>
              </Col>
            </Row>

            {/* controls for chats subjects */}
            <Row>
              <Col xs={12}>
                <Tabs
                  defaultIndex={tab === "General" ? 0 : tab === "Books" ? 1 : 2}
                  className="profile-tabs-parent"
                >
                  <TabList className="tabs-controls">
                    <Tab>
                      <div
                        onClick={() => {
                          handleChangeTab("General");
                          setMessagesList(null);
                          setOpenedChatContent("");

                          setCurrentChat(0);
                        }}
                      >
                        عامة
                      </div>
                      {unread && (
                        <span className="notification">{unread?.general}</span>
                      )}
                    </Tab>
                    <Tab>
                      <div
                        onClick={() => {
                          handleChangeTab("Books");
                          setMessagesList(null);
                          setOpenedChatContent("");
                          setNoChatsOpened(true);
                          setCurrentChat(0);
                        }}
                      >
                        الكتب
                      </div>
                      {unread && (
                        <span className="notification">{unread?.books}</span>
                      )}
                    </Tab>
                    <Tab>
                      <div
                        onClick={() => {
                          handleChangeTab("Services");
                          setMessagesList(null);
                          setOpenedChatContent("");
                          setNoChatsOpened(true);
                          setCurrentChat(0);
                        }}
                      >
                        الصفقات
                      </div>
                      {unread && (
                        <span className="notification">{unread?.services}</span>
                      )}
                    </Tab>
                  </TabList>
                  <TabPanel>
                    <Row>
                      <Col lg={4}>
                        <div className="messages-sidebar-parent scrollable-sec">
                          {messagesList?.length > 0 ? (
                            messagesList?.map((item) => {
                              return (
                                <div
                                  key={item?.id}
                                  className={`cont d-flex align-items-center ${currentchat ==
                                    item?.id && "active"}`}
                                  onClick={() =>
                                    handleOpenChat(item?.id, "General")
                                  }
                                >
                                  <div className="cont-img ml-2">
                                    <LazyLoadImage
                                      src={
                                        item?.send.id == getCookie("userId")
                                          ? item?.receive.avatar
                                          : item?.send.avatar
                                      }
                                      alt=""
                                    />
                                  </div>
                                  <div className="cont-txt">
                                    <h2>
                                      {item?.send.id == getCookie("userId")
                                        ? item?.receive.name
                                        : item?.send.name}

                                      {/* {item?.unread != 0 ? (
                                          <span className="message-number">
                                            {item?.unread}
                                          </span>
                                        ) : null} */}
                                    </h2>
                                    <p>{item?.title}</p>
                                  </div>
                                </div>
                              );
                            })
                          ) : (
                            <div className="cont py-2 d-flex align-items-center justify-content-center">
                              <div className="cont-txt">
                                <p>لا يوجد رسائل لعرضها</p>
                              </div>
                            </div>
                          )}
                        </div>
                      </Col>
                      <Col lg={8}>
                        {noChatsOpened ? (
                          <div className="nochats d-flex align-items-center justify-content-center">
                            <div className="cont">
                              <LazyLoadImage src={noChats} alt="" />
                              <p className="mt-2">
                                لم تقم باختيار اى رسالة لعرضها
                              </p>
                            </div>
                          </div>
                        ) : (
                          <div className="chat-body">
                            {openedChatContent ? (
                              <div className="chat-heading d-flex align-items-center justify-content-between">
                                <h3>{openedChatContent?.chat?.title}</h3>
                                <div className="username">
                                  <Link
                                    to={`/profile/${openedChatContent?.chat?.send.id}`}
                                  >
                                    <i>
                                      <FontAwesomeIcon icon={faUser} />
                                    </i>
                                    {openedChatContent?.chat?.send.name}
                                  </Link>
                                </div>
                              </div>
                            ) : null}

                            <div
                              className="messages-list-parent scrollable-sec"
                              id="General"
                              ref={chatContainer}
                            >
                              {openedChatContent &&
                              openedChatContent.message?.length > 0
                                ? openedChatContent.message.map((item) => {
                                    return item.send?.id !=
                                      getCookie("userId") ? (
                                      <div
                                        key={item?.id}
                                        className="cont d-flex"
                                      >
                                        <div>
                                          <div className="cont-img">
                                            <NavLink
                                              to={`/profile/${item.send.id}`}
                                            >
                                              <LazyLoadImage
                                                src={item?.send.avatar}
                                                alt=""
                                              />
                                            </NavLink>
                                          </div>
                                        </div>
                                        <div className="cont-txt">
                                          {item?.message === null ? null : (
                                            <div className="txt">
                                              {validUrl.isUri(item?.message) ? (
                                                <a
                                                  href={item?.message}
                                                  target="_blank"
                                                  alt="link"
                                                >
                                                  {item?.message}
                                                </a>
                                              ) : (
                                                <p>{item?.message}</p>
                                              )}
                                            </div>
                                          )}

                                          <div className="cont-image">
                                            {item?.image === null ? null : (
                                              <LazyLoadImage
                                                src={item?.image}
                                                alt=""
                                              />
                                            )}
                                          </div>
                                          <div className="time d-flex align-items-center">
                                            <i>
                                              <FontAwesomeIcon icon={faClock} />
                                            </i>
                                            <span>{item?.created_at}</span>
                                          </div>
                                        </div>
                                      </div>
                                    ) : (
                                      <div
                                        key={item?.id}
                                        className="cont d-flex green-reverse"
                                      >
                                        <div>
                                          <div className="cont-img">
                                            <NavLink
                                              to={`/profile/${item.send.id}`}
                                            >
                                              <LazyLoadImage
                                                src={item?.send.avatar}
                                                alt=""
                                              />
                                            </NavLink>
                                          </div>
                                        </div>
                                        <div className="cont-txt">
                                          {item?.message === null ? null : (
                                            <div className="txt">
                                              {validUrl.isUri(item?.message) ? (
                                                <a
                                                  href={item?.message}
                                                  target="_blank"
                                                  alt="link"
                                                >
                                                  {item?.message}
                                                </a>
                                              ) : (
                                                <p>{item?.message}</p>
                                              )}
                                            </div>
                                          )}

                                          <div className="cont-image">
                                            {item?.image === null ? null : (
                                              <LazyLoadImage
                                                src={item?.image}
                                                alt=""
                                              />
                                            )}
                                          </div>
                                          <div className="time d-flex align-items-center">
                                            <i>
                                              <FontAwesomeIcon icon={faClock} />
                                            </i>
                                            <span>{item?.created_at}</span>
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  })
                                : null}
                            </div>

                            <div className="send-section">
                              {sendingMessageContent === "" ||
                              galleryImg === "" ? (
                                <>
                                  {alertErrors && (
                                    <div className="errors-alert">
                                      <FontAwesomeIcon
                                        className="alert-icon"
                                        icon={faInfoCircle}
                                      ></FontAwesomeIcon>
                                      <span>
                                        برجاء اختار صورة او اكتب رسالة{" "}
                                      </span>
                                    </div>
                                  )}
                                </>
                              ) : null}
                              <form
                                className="d-flex align-items-center justify-content-between"
                                onSubmit={(e) =>
                                  handleSendNewMssage(e, "General")
                                }
                              >
                                <input
                                  type="text"
                                  name="message"
                                  id="sendingMessageContent"
                                  onChange={handleChange}
                                  value={sendingMessageContent}
                                  placeholder="اكتب هنا ردك على الرسالة"
                                />
                                {galleryImg ? (
                                  <span className="fileName">
                                    {inputFileName}
                                  </span>
                                ) : null}
                                <span className="btn btn-file">
                                  ارفاق ملف
                                  <input
                                    type="file"
                                    id="chatImages"
                                    onChange={handleImageUpload}
                                  />
                                </span>

                                <button
                                  className="btn primary-btn"
                                  type="submit"
                                >
                                  <i>
                                    <FontAwesomeIcon icon={faPaperPlane} />
                                  </i>
                                  إرسال رد
                                </button>
                              </form>
                            </div>
                          </div>
                        )}
                      </Col>
                    </Row>
                  </TabPanel>
                  <TabPanel>
                    <Row>
                      <Col lg={4}>
                        <div className="messages-sidebar-parent scrollable-sec">
                          {messagesList?.length > 0 ? (
                            messagesList.map((item) => {
                              return (
                                <div
                                  key={item?.id}
                                  className={`cont d-flex align-items-center ${currentchat ==
                                    item?.id && "active"}`}
                                  onClick={() =>
                                    handleOpenChat(item?.id, "Books")
                                  }
                                >
                                  <div className="cont-img ml-2">
                                    <LazyLoadImage
                                      src={
                                        item?.send.id == getCookie("userId")
                                          ? item?.receive.avatar
                                          : item?.send.avatar
                                      }
                                      alt=""
                                    />
                                  </div>
                                  <div className="cont-txt">
                                    <h2>
                                      {item?.send.id == getCookie("userId")
                                        ? item?.receive.name
                                        : item?.send.name}{" "}
                                      {item?.unread == 1 ? (
                                        <span className="message-number">
                                          {item?.unread}
                                        </span>
                                      ) : null}
                                    </h2>
                                    <p>{item?.title}</p>
                                  </div>
                                </div>
                              );
                            })
                          ) : (
                            <div className="cont py-2 d-flex align-items-center justify-content-center">
                              <div className="cont-txt">
                                <p>لا يوجد رسائل لعرضها</p>
                              </div>
                            </div>
                          )}
                        </div>
                      </Col>
                      <Col lg={8}>
                        {noChatsOpened ? (
                          <div className="nochats d-flex align-items-center justify-content-center">
                            <div className="cont">
                              <LazyLoadImage src={noChats} alt="" />
                              <p className="mt-2">
                                لم تقم باختيار اى رسالة لعرضها
                              </p>
                            </div>
                          </div>
                        ) : (
                          <div className="chat-body">
                            {openedChatContent ? (
                              <div className="chat-heading d-flex align-items-center justify-content-between">
                                <h3>
                                  <Link
                                    to={`/books/${openedChatContent?.chat?.model_id}`}
                                  >
                                    {openedChatContent?.chat?.title}
                                  </Link>
                                </h3>
                                <div className="username">
                                  <Link
                                    to={`/profile/${openedChatContent?.chat?.send.id}`}
                                  >
                                    <i>
                                      <FontAwesomeIcon icon={faUser} />
                                    </i>
                                    {openedChatContent?.chat?.send.name}
                                  </Link>
                                </div>
                              </div>
                            ) : null}

                            <div
                              className="messages-list-parent scrollable-sec"
                              ref={chatContainer}
                              id="Books"
                            >
                              {openedChatContent &&
                              openedChatContent?.message?.length > 0
                                ? openedChatContent?.message?.map((item) => {
                                    return item.send?.id !=
                                      getCookie("userId") ? (
                                      <div
                                        key={item?.id}
                                        className="cont d-flex"
                                      >
                                        <div>
                                          <div className="cont-img">
                                            <NavLink
                                              to={`/profile/${item.send.id}`}
                                            >
                                              <LazyLoadImage
                                                src={item?.send.avatar}
                                                alt=""
                                              />
                                            </NavLink>
                                          </div>
                                        </div>
                                        <div className="cont-txt">
                                          {item?.message === null ? null : (
                                            <div className="txt">
                                              {validUrl.isUri(item?.message) ? (
                                                <a
                                                  href={item?.message}
                                                  target="_blank"
                                                  alt="link"
                                                >
                                                  {item?.message}
                                                </a>
                                              ) : (
                                                <p>{item?.message}</p>
                                              )}
                                            </div>
                                          )}

                                          <div className="cont-image">
                                            {item?.image === null ? null : (
                                              <LazyLoadImage
                                                src={item?.image}
                                                alt=""
                                              />
                                            )}
                                          </div>
                                          <div className="time d-flex align-items-center">
                                            <i>
                                              <FontAwesomeIcon icon={faClock} />
                                            </i>
                                            <span>{item?.created_at}</span>
                                          </div>
                                        </div>
                                      </div>
                                    ) : (
                                      <div
                                        key={item?.id}
                                        className="cont d-flex green-reverse"
                                      >
                                        <div>
                                          <div className="cont-img">
                                            <NavLink
                                              to={`/profile/${item.send.id}`}
                                            >
                                              <LazyLoadImage
                                                src={item?.send.avatar}
                                                alt=""
                                              />
                                            </NavLink>
                                          </div>
                                        </div>
                                        <div className="cont-txt">
                                          {item?.message === null ? null : (
                                            <div className="txt">
                                              {validUrl.isUri(item?.message) ? (
                                                <a
                                                  href={item?.message}
                                                  target="_blank"
                                                  alt="link"
                                                >
                                                  {item?.message}
                                                </a>
                                              ) : (
                                                <p>{item?.message}</p>
                                              )}
                                            </div>
                                          )}

                                          <div className="cont-image">
                                            {item?.image === null ? null : (
                                              <LazyLoadImage
                                                src={item?.image}
                                                alt=""
                                              />
                                            )}
                                          </div>
                                          <div className="time d-flex align-items-center">
                                            <i>
                                              <FontAwesomeIcon icon={faClock} />
                                            </i>
                                            <span>{item?.created_at}</span>
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  })
                                : null}
                            </div>

                            <div className="send-section">
                              {sendingMessageContent === "" ||
                              galleryImg === "" ? (
                                <>
                                  {alertErrors && (
                                    <div className="errors-alert">
                                      <FontAwesomeIcon
                                        className="alert-icon"
                                        icon={faInfoCircle}
                                      ></FontAwesomeIcon>
                                      <span>
                                        برجاء اختار صورة او اكتب رسالة{" "}
                                      </span>
                                    </div>
                                  )}
                                </>
                              ) : null}
                              <form
                                className="d-flex align-items-center justify-content-between"
                                onSubmit={(e) =>
                                  handleSendNewMssage(e, "Books")
                                }
                              >
                                <input
                                  type="text"
                                  name="message"
                                  id="sendingMessageContent"
                                  onChange={handleChange}
                                  value={sendingMessageContent}
                                  placeholder="اكتب هنا ردك على الرسالة"
                                />
                                {galleryImg ? (
                                  <span className="fileName">
                                    {inputFileName}
                                  </span>
                                ) : null}
                                <span className="btn btn-file">
                                  ارفاق ملف
                                  <input
                                    type="file"
                                    id="chatImages"
                                    onChange={handleImageUpload}
                                  />
                                </span>

                                <button
                                  className="btn primary-btn"
                                  type="submit"
                                >
                                  <i>
                                    <FontAwesomeIcon icon={faPaperPlane} />
                                  </i>
                                  إرسال رد
                                </button>
                              </form>
                            </div>
                          </div>
                        )}
                      </Col>
                    </Row>
                  </TabPanel>
                  <TabPanel>
                    <Row>
                      <Col lg={4}>
                        <div className="messages-sidebar-parent scrollable-sec">
                          {messagesList?.length > 0 ? (
                            messagesList.map((item) => {
                              return (
                                <div
                                  key={item?.id}
                                  className={`cont d-flex align-items-center ${currentchat ==
                                    item?.id && "active"}`}
                                  onClick={() =>
                                    handleOpenChat(item?.id, "Services")
                                  }
                                >
                                  <div className="cont-img ml-2">
                                    <LazyLoadImage
                                      src={
                                        item?.send.id == getCookie("userId")
                                          ? item?.receive.avatar
                                          : item?.send.avatar
                                      }
                                      alt=""
                                    />
                                  </div>
                                  <div className="cont-txt">
                                    <h2>
                                      {item?.send.id == getCookie("userId")
                                        ? item?.receive.name
                                        : item?.send.name}{" "}
                                      {item?.unread == 1 ? (
                                        <span className="message-number">
                                          {item?.unread}
                                        </span>
                                      ) : null}
                                    </h2>
                                    <p>{item?.title}</p>
                                  </div>
                                </div>
                              );
                            })
                          ) : (
                            <div className="cont py-2 d-flex align-items-center justify-content-center">
                              <div className="cont-txt">
                                <p>لا يوجد رسائل لعرضها</p>
                              </div>
                            </div>
                          )}
                        </div>
                      </Col>
                      <Col lg={8}>
                        {noChatsOpened ? (
                          <div className="nochats d-flex align-items-center justify-content-center">
                            <div className="cont">
                              <LazyLoadImage src={noChats} alt="" />
                              <p className="mt-2">
                                لم تقم باختيار اى رسالة لعرضها
                              </p>
                            </div>
                          </div>
                        ) : (
                          <div className="chat-body">
                            {openedChatContent ? (
                              <div className="chat-heading d-flex align-items-center justify-content-between">
                                <h3>
                                  <Link
                                    to={`/deals/${openedChatContent?.chat?.model_id}`}
                                  >
                                    {openedChatContent?.chat?.title}
                                  </Link>{" "}
                                </h3>
                                <div className="username">
                                  <Link
                                    to={`/profile/${openedChatContent?.chat?.send.id}`}
                                  >
                                    <i>
                                      <FontAwesomeIcon icon={faUser} />
                                    </i>
                                    {openedChatContent?.chat?.send.name}
                                  </Link>
                                </div>
                              </div>
                            ) : null}

                            <div
                              className="messages-list-parent scrollable-sec"
                              ref={chatContainer}
                              id="Books"
                            >
                              {openedChatContent &&
                              openedChatContent?.message?.length > 0
                                ? openedChatContent?.message?.map((item) => {
                                    return item.send?.id !=
                                      getCookie("userId") ? (
                                      <div
                                        key={item?.id}
                                        className="cont d-flex"
                                      >
                                        <div>
                                          <div className="cont-img">
                                            <NavLink
                                              to={`/profile/${item.send.id}`}
                                            >
                                              <LazyLoadImage
                                                src={item?.send?.avatar}
                                                alt=""
                                              />
                                            </NavLink>
                                          </div>
                                        </div>
                                        <div className="cont-txt">
                                          {item?.message === null ? null : (
                                            <div className="txt">
                                              {validUrl.isUri(item?.message) ? (
                                                <a
                                                  href={item?.message}
                                                  target="_blank"
                                                  alt="link"
                                                >
                                                  {item?.message}
                                                </a>
                                              ) : (
                                                <p>{item?.message}</p>
                                              )}
                                            </div>
                                          )}

                                          <div className="cont-image">
                                            {item?.image === null ? null : (
                                              <LazyLoadImage
                                                src={item?.image}
                                                alt=""
                                              />
                                            )}
                                          </div>
                                          <div className="time d-flex align-items-center">
                                            <i>
                                              <FontAwesomeIcon icon={faClock} />
                                            </i>
                                            <span>{item?.created_at}</span>
                                          </div>
                                        </div>
                                      </div>
                                    ) : (
                                      <div
                                        key={item?.id}
                                        className="cont d-flex green-reverse"
                                      >
                                        <div>
                                          <div className="cont-img">
                                            <NavLink
                                              to={`/profile/${item.send.id}`}
                                            >
                                              <LazyLoadImage
                                                src={item?.send?.avatar}
                                                alt=""
                                              />
                                            </NavLink>
                                          </div>
                                        </div>
                                        <div className="cont-txt">
                                          {item?.message === null ? null : (
                                            <div className="txt">
                                              {validUrl.isUri(item?.message) ? (
                                                <a
                                                  href={item?.message}
                                                  target="_blank"
                                                  alt="link"
                                                >
                                                  {item?.message}
                                                </a>
                                              ) : (
                                                <p>{item?.message}</p>
                                              )}
                                            </div>
                                          )}

                                          <div className="cont-image">
                                            {item?.image === null ? null : (
                                              <LazyLoadImage
                                                src={item?.image}
                                                alt=""
                                              />
                                            )}
                                          </div>
                                          <div className="time d-flex align-items-center">
                                            <i>
                                              <FontAwesomeIcon icon={faClock} />
                                            </i>
                                            <span>{item?.created_at}</span>
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  })
                                : null}
                            </div>

                            <div className="send-section">
                              {sendingMessageContent === "" ||
                              galleryImg === "" ? (
                                <>
                                  {alertErrors && (
                                    <div className="errors-alert">
                                      <FontAwesomeIcon
                                        className="alert-icon"
                                        icon={faInfoCircle}
                                      ></FontAwesomeIcon>
                                      <span>
                                        برجاء اختار صورة او اكتب رسالة{" "}
                                      </span>
                                    </div>
                                  )}
                                </>
                              ) : null}
                              <form
                                className="d-flex align-items-center justify-content-between"
                                onSubmit={(e) =>
                                  handleSendNewMssage(e, "Services")
                                }
                              >
                                <input
                                  type="text"
                                  name="message"
                                  id="sendingMessageContent"
                                  onChange={handleChange}
                                  value={sendingMessageContent}
                                  placeholder="اكتب هنا ردك على الرسالة"
                                />
                                {galleryImg ? (
                                  <span className="fileName">
                                    {inputFileName}
                                  </span>
                                ) : null}
                                <span className="btn btn-file">
                                  ارفاق ملف
                                  <input
                                    type="file"
                                    id="chatImages"
                                    onChange={handleImageUpload}
                                  />
                                </span>

                                <button
                                  className="btn primary-btn"
                                  type="submit"
                                >
                                  <i>
                                    <FontAwesomeIcon icon={faPaperPlane} />
                                  </i>
                                  إرسال رد
                                </button>
                              </form>
                            </div>
                          </div>
                        )}
                      </Col>
                    </Row>
                  </TabPanel>
                </Tabs>
              </Col>
            </Row>
          </Container>
        </section>
      )}
    </>
  );
};

export default MessagesList;
