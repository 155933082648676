import {
  faFacebookF,
  faInstagram,
  faSnapchatGhost,
  faTwitter,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { NavLink, withRouter } from "react-router-dom";
import { getData } from "../../../Services/DataService";
import NavMenu from "./NavMenu";
import TopBar from "./TopBar";
import "./index.scss";

const Header = (props) => {
  const [servicesCount, setServicesCount] = useState("");
  const [searchInputText, setSearchInputText] = useState("");
  const [dealType, setDealType] = useState("");

  useEffect(() => {
    getData(`api/services-count`).then(
      (response) => {
        const data = response?.data?.data;
        setServicesCount(data);
      },
      (error) => {
        setServicesCount(0);
      }
    );
  }, []);

  const handleSubmitSearch = (e) => {
    e.preventDefault();
    props.history.push(
      `/search/${dealType ? dealType : "books"}?${
        searchInputText.length > 0 ? searchInputText : "all"
      }`
    );
  };

  return (
    <>
      <TopBar
        social={props.mainsettings.socialmedia}
        data={props.data}
        canMessage={props.message}
      />
      <Container className="header">
        <Row className="flx-row">
          <Col md={4} className="txt-right m-center">
            <NavLink to="/">
              <img
                src={props.mainsettings?.logo?.ar}
                alt={`${props?.mainsettings?.title?.ar}`}
              />
            </NavLink>
          </Col>
          <Col md={8}>
            <Form
              className="Search-parent d-flex"
              onSubmit={handleSubmitSearch}
            >
              <div className="input-parent">
                <input
                  type="text"
                  name="searchInputText"
                  onChange={(e) => setSearchInputText(e.target.value)}
                  placeholder="  المؤلف , الكتاب , ISBN , الصفقة"
                />
              </div>
              <Form.Group className={`search-select`}>
                <Form.Control
                  as="select"
                  className={`form-select ${
                    props.errorsObj && props.errorsObj.used_status
                      ? "has-error"
                      : null
                  }`}
                  id="searchType"
                  onChange={(e) => setDealType(e.target.value)}
                >
                  <option value="books">الكتب</option>
                  <option value="deals">الصفقات</option>
                  <option value="members">الأعضاء</option>
                </Form.Control>
              </Form.Group>
              <Button type="submit" className="red-btn bold">
                ابحث
              </Button>
            </Form>
          </Col>
        </Row>
        <div className="mobile-nav" id="menuToggle">
          <Row>
            <Col>
              <NavMenu />
            </Col>

            <>
              <div className="menu-btn-parent">
                <NavLink
                  to="/books/add"
                  className="add-book-btn all-btn xl-btn"
                >
                  <FontAwesomeIcon className="add-book-icon" icon={faPlus} />
                  إضافة كتاب
                </NavLink>
              </div>

              <div className="col-md-auto">
                <div className="menu-btn-parent">
                  <NavLink
                    to="/deals"
                    className="btn selles-btn primary-btn xl-btn inline-centerd"
                  >
                    صفقات البيع و الشراء
                    <span className="mx-2">({servicesCount})</span>
                  </NavLink>
                </div>
              </div>
            </>

            {window.innerWidth <= 768 ? (
              <Col xs={12}>
                <div className="social-parent">
                  <a
                    href="https://www.facebook.com/"
                    className="social-link"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon icon={faFacebookF} />
                  </a>
                  <a
                    href="https://www.instagram.com/"
                    className="social-link"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon icon={faInstagram} />
                  </a>
                  <a
                    href="https://twitter.com/"
                    className="social-link"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon icon={faTwitter} />
                  </a>
                  <a
                    href="https://www.youtube.com/"
                    className="social-link"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon icon={faYoutube} />
                  </a>
                  <a
                    href="https://www.snapchat.com/"
                    className="social-link"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon icon={faSnapchatGhost} />
                  </a>
                </div>
              </Col>
            ) : null}
          </Row>
        </div>
      </Container>
    </>
  );
};

export default withRouter(Header);
