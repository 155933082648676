import React, {Component, useEffect, useState} from "react";
import {Container} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faInfoCircle} from "@fortawesome/free-solid-svg-icons";
import "./Banner.scss";
import Slider from "react-slick";
import axios from "axios";
import BaseUrl from "../../../config";
import {getData} from "../../../Services/DataService";

const Banner = () => {
    const [banners, setBanners] = useState([]);

    useEffect(() => {
        getData(`api/sliders`).then(
            (response) => {
                const banners = response?.data?.data;
                setBanners(banners);
            },
            (error) => {
                setBanners([]);
            }
        );
    }, []);

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: Number(banners?.length) >= 3 ? 3 : 1,
        slidesToScroll: 1,
        arrows: false,
        rtl: true,
        responsive: [
            {
                breakpoint: 922,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    dots: true,
                    arrows: false,
                },
            },
            {
                breakpoint: 580,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: true,
                    arrows: false,
                },
            },
        ],
    };
    return (
        <>
            <section className="banner-sec">
                <Container>
                    <Slider {...settings} className="banner-slider">
                        {banners?.length > 0 ? ( banners?.map((banner) => {
                                return (
                                    <div key={banner.id} className="banner-parent add_margin">
                                        <a target="_blank" href={`${banner?.url}`}>
                                            <img
                                                src={banner.image}
                                                title={`${banner.title.ar}`}
                                                alt={`${banner.title.ar}`}
                                            />
                                        </a>
                                    </div>
                                );
                            })
                        ) : (
                            <div className="errors-alert">
                                <FontAwesomeIcon
                                    className="alert-icon"
                                    icon={faInfoCircle}
                                ></FontAwesomeIcon>
                                <span>لا توجد كتب لعرضها </span>
                            </div>
                        )}
                    </Slider>
                </Container>
            </section>
        </>
    );
};

export default Banner;
