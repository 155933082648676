import { NavLink, useHistory } from "react-router-dom";
import { getData } from "../../../Services/DataService";
import {
  faCheckCircle,
  faThLarge,
  faThList,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Loading from "../../../Components/Blocks/Loading";

const SearchResultMembers = (props) => {
  const [categoryData, setCategoryData] = useState([]);
  const [currentPage, setCurrentPage] = useState([]);
  const [nextPage, setNextPage] = useState([]);
  const [prevPage, setPrevPage] = useState([]);
  const [LastPage, setLastPage] = useState([]);
  const [totalPages, setTotalPages] = useState([]);
  const [currentLink, setCurrentLink] = useState([]);
  const [last_page, setLast_page] = useState([]);
  const [Pages, setPages] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [activeBtn, setActiveBtn] = useState(false);
  const [bookCity, setBookCity] = useState("");
  const [loading, setLoading] = useState(true);
  const [sellers, setSellers] = useState("");
  const [gridView, setGridView] = useState("");
  const hanldeView = () => {
    setGridView(!gridView);
  };
  const history = useHistory();
  useEffect(() => {
    getData(
      `api/search?search_type=members&search=${
        props.searchData == "all" ? "" : props.searchData
      }`
    )
      .then(
        (response) => {
          const data = response?.data?.data?.data;
          setSellers(data);
          setLoading(false);
        },
        (error) => {
          setSellers([]);
          setLoading(false);
        }
      )
      .finally(() => {
        setLoading(false);
      });
  }, [props]);

  return (
    <>
      <section className="bestSeller">
        <Container>
          {loading && <Loading />}
          {/* <Sugar customLoading={loading} color={'#00A388'} background="#87D4C7"/> */}
          <Row>
            <Col xs={12}>
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <NavLink to="/" className="bredcrumb-link">
                    الرئيسية
                  </NavLink>
                </li>

                <li className="breadcrumb-item">
                  <span className="mob-bredcrumb active">
                    نتائج بحث الاعضاء
                  </span>
                </li>
              </ul>
            </Col>
          </Row>

          <Row>
            <Col xs={12}>
              <div className="new-offer">
                <div className="controls">
                  <div
                    className={`grid ${gridView == true && "active"}`}
                    onClick={hanldeView}
                  >
                    <FontAwesomeIcon
                      icon={faThLarge}
                      className="control-icon"
                    />
                  </div>
                  <div
                    className={`list ${gridView == false && "active"}`}
                    onClick={hanldeView}
                  >
                    <FontAwesomeIcon icon={faThList} className="control-icon" />
                  </div>
                </div>
              </div>
            </Col>

            {sellers && sellers.length > 0 ? (
              sellers.map((item) => {
                return (
                  <Col xs={12} lg={gridView && 4} key={item.id}>
                    <div
                      className={`offers-list flx-start ${gridView &&
                        "grid-parent"}`}
                    >
                      <div className="cont-user">
                        <div className="info">
                          <div
                            className="cont-img"
                            style={{
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              history.push(`/profile/${item.id}`);
                            }}
                          >
                            <img src={`${item.avatar}`} alt="" />
                          </div>
                          <div className="cont-txt">
                            <h2 className="author-name">
                              <NavLink to={`/profile/${item.id}`}>
                                {item?.name?.length > 10
                                  ? `${item?.name?.slice(0, 10)}...`
                                  : item?.name}

                                <span
                                  className={`${!Number(item?.verified) &&
                                    "rejectedVrf"}`}
                                >
                                  {item?.verified !== 0 ? (
                                    <FontAwesomeIcon icon={faCheckCircle} />
                                  ) : (
                                    "غير موثق"
                                  )}
                                </span>
                              </NavLink>
                            </h2>
                            <div className="author-info flx-start">
                              <p className="author-books">
                                عدد الكتب {item?.user_books}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="rateing submit-offer">
                          <p>(تقييم 5)</p>
                        </div>
                      </div>
                    </div>
                  </Col>
                );
              })
            ) : (
              <Col xs={12} lg={7} className="no-content-show mx-auto">
                <div className="cont-txt">
                  <p>لا يوجد معلنين للعرض</p>
                </div>
              </Col>
            )}
          </Row>
        </Container>
      </section>
    </>
  );
};
export default SearchResultMembers;
