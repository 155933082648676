import React, { Component,useState } from 'react';
import { Container , Row , Col , Form , Button} from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faEye , faUser , faClock , faCommentDots , faChevronLeft , faChevronRight  , faThLarge , faThList ,faInfoCircle} from '@fortawesome/free-solid-svg-icons';
import BaseUrl from '../../../config';
import axios from 'axios';
import { Sugar } from 'react-preloaders';
import { getData } from '../../../Services/DataService';
import { useEffect } from 'react';
import Loading from '../../../Components/Blocks/Loading';

const SearchResultDeals = (props) => {
    const[deals,setDeals]=useState([]);
    const[currentPage,setCurrentPage]=useState([]);
    const[nextPage,setNextPage]=useState([]);
    const[prevPage,setPrevPage]=useState([]);
    const[LastPage,setLastPage]=useState([]);
    const[totalPages,setTotalPages]=useState([]);
    const[currentLink,setCurrentLink]=useState([]);
    const[last_page,setLast_page]=useState([]);
    const[Pages,setPages]=useState([]);
    const[loading,setLoading]=useState(true);
    const[isOpen,setIsOpen]=useState(false);
    const[activeBtn,setActiveBtn]=useState(false);
    const[bookCity,setBookCity]=useState('');
    const[gridView,setGridView]=useState(false);
    const[dealSearchName,setDealSearchName]=useState('');
    const[dealSearchType,setDealSearchType]=useState('');

useEffect(() => {
    getData(`api/search?search_type=deals&search=${props.searchData == "all" ? "" : props.searchData }`).then((response) => {
        setLoading(false);
        const deals = response?.data?.data?.data;
        setDeals(deals);
        setCurrentPage(deals?.current_page);
        setNextPage(deals?.next_page_url);
        setPrevPage(deals?.prev_page_url);
        setLastPage(deals?.last_page_url);
        setTotalPages(deals?.last_page);
        setCurrentLink(deals?.path);
        setLast_page(deals?.last_page);
        setPages(response.data.pages);
    },(error) => {
        setDeals([]);
        setLoading(false);
    })
},[props])

const fillData =(url)=>{
    getData(url).then((response) => {
        const deals = response?.data?.data?.data;
            setDeals(deals);
            setCurrentPage(deals?.current_page);
            setNextPage(deals?.next_page_url);
            setPrevPage(deals?.prev_page_url);
            setLastPage(deals?.last_page_url);
            setTotalPages(deals?.last_page);
            setCurrentLink(deals?.path);
            setLast_page(deals?.last_page);
            setPages(response.data.pages);
            setLoading(false);
            window.scrollTo({ top: 0, behavior: 'smooth' });
    }, (error) => {
        setDeals([]);
        setLoading(false);
        window.scrollTo({ top: 0, behavior: 'smooth' });
    })
}
const hanldeView = () => {
    setGridView(!gridView)
 }

const handleNextPage = (id) => {
    fillData(nextPage);
}

const handlePrevPage = () => {
    fillData(prevPage);
};

const handleGoToPage = (id) => {
    let pageNumber = id
    fillData(`${currentLink}?page=${pageNumber}`);
};

    const pages = Pages?.length ? Pages?.map((page,index)=>{
        return(

            <li key={index} className={Number(currentPage) === page ? `active`: ''} onClick={()=>handleGoToPage(page)}>
                <button>
                    {page}
                </button>
            </li>

        )
    }):null
    return (
        <>
       <section className="Deal-list-sec">
                <Container>
                  {/* <Sugar customLoading={loading} color={'#00A388'} background="#87D4C7"/> */}
                    {loading && <Loading/>}
                    <Row>
                        <Col xs={12}>
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item t-none">
                                    <NavLink to='/' className="bredcrumb-link">
                                            الرئيسية
                                    </NavLink>
                                </li>
                                <li className="breadcrumb-item current">
                                    <span className="mob-bredcrumb active">نتائج البحث</span>
                                </li>
                            </ul>
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={12}>
                            <div className="new-offer">
                                <div className="controls">
                                    <div className={`grid ${gridView == true && 'active'}`} onClick={hanldeView}>
                                        <FontAwesomeIcon  icon={faThLarge} className="control-icon"/>
                                    </div>
                                    <div className={`list ${gridView == false && 'active'}`} onClick={hanldeView} >
                                        <FontAwesomeIcon  icon={faThList} className="control-icon"/>
                                    </div>
                                </div>
                            </div>
                        </Col>

                            { deals && deals.length > 0 ? deals.map( item => {
                                return (
                                    <Col xs={12} lg={gridView && 4} key={item.id}>
                                        <div className={`offers-list flx-centered ${gridView && 'grid-parent'}`}>
                                            <div className="cont flx-centered">
                                                <div className="cont-img">
                                                    <img src={`${item.images[0]}`} alt={item?.title?.ar}/>
                                                </div>
                                                <div className="cont-txt">
                                                    <h2>
                                                        <NavLink to={`/deals/${item.id}`}>{item?.title?.ar.length > 20 ? item?.title?.ar?.slice(0, 20).concat('...') : item?.title?.ar }</NavLink>
                                                    </h2>
                                                    <div className="offer-details">
                                                            <div className="service-user">
                                                                <FontAwesomeIcon  icon={faUser} className="register-icon"/>
                                                                <span>{item.user_id.name}</span>
                                                            </div>

                                                            <div>
                                                                <FontAwesomeIcon  icon={faClock} className="register-icon"/>
                                                                <span>{item.created_at}</span>
                                                            </div>

                                                            <div>
                                                                <FontAwesomeIcon  icon={faCommentDots} className="register-icon"/>
                                                                <span>{item.order_count} متقدم</span>
                                                            </div>

                                                            <div>
                                                                <FontAwesomeIcon  icon={faEye} className="register-icon"/>
                                                                <span>{item.views} مشاهدة</span>
                                                            </div>

                                                    </div>
                                                </div>
                                            </div>
                                            <div className="submit-offer">
                                                <NavLink to={`/deals/${item.id}`} className="btn primary-btn">
                                                    قدم عرض
                                                </NavLink>
                                            </div>
                                        </div>
                                    </Col>
                                )
                            }):

                                <Col xs={12} lg={7}  className='no-content-show mx-auto'>
                                    <div className='cont-txt'>
                                        <p>
                                            لا يوجد صفقات لعرضها
                                        </p>

                                    </div>
                                </Col>

                            }
                    </Row>
                    <Row>
                        <Col>
                            {parseInt(totalPages) > 1 ? (
                                <ul className="pagination-parent flx-centered">
                                    <li className="first-page">
                                        <button onClick={handlePrevPage} className={currentPage == "1"? `disabled-btn`: `arrow-btn`}>
                                            <FontAwesomeIcon icon={faChevronRight} />
                                        </button>
                                    </li>

                                    {
                                        (currentPage != 1) && (currentPage != 2) &&
                                        <li className='test-last' onClick={() => handleGoToPage(1)} >
                                            <button>1</button>
                                        </li>
                                    }
                                    {
                                        (currentPage != 1) && (currentPage != 2) &&
                                        <li className='to-continue'>...</li>
                                    }


                                    {Pages}

                                    {
                                        (totalPages != currentPage) && (currentPage != (Number(totalPages) - 1)) &&
                                        <li className='to-continue'>...</li>
                                    }
                                    {
                                        (totalPages != currentPage) && (currentPage != (Number(totalPages) - 1))  &&

                                        <li className='test-last' onClick={() => handleGoToPage(totalPages)} >
                                            <button>{totalPages}</button>
                                        </li>
                                    }


                                    {/* {currentPage != } */}
                                    <li className="last-page">
                                        <button onClick={handleNextPage} className={currentPage == totalPages ? `disabled-btn`: `arrow-btn`}>
                                            <FontAwesomeIcon icon={faChevronLeft} />
                                        </button>
                                    </li>
                                </ul>
                            ) : null}
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
}

export default SearchResultDeals;