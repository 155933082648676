import axios from "axios";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { BrowserRouter } from "react-router-dom";
import Cookies from "universal-cookie";
import "./App.scss";
import SearchProvider from "./Components/Auth/WebsiteContext/SearchContext/SearchProvider";
import Footer from "./Components/Layouts/Footer";
import Header from "./Components/Layouts/Header";
import AppRoutes from "./Routes/AppRoutes";
import { getData } from "./Services/DataService";
import Ticker from "./pages/Home/Ticker/Ticker";
import AlertMessage from "./Components/Layouts/AlertMessage/AlertMessage";
import { toast } from "react-toastify";
function App() {
  const [mainsettings, setMainsettings] = useState("");
  const [service, setService] = useState(0);
  const [message, setMessage] = useState(0);
  const [errorsObj, setErrorsObj] = useState("");
  const [open, setOpen] = useState(true);
  const [closeMessage, setCloseMessage] = React.useState("");
  const cookies = new Cookies();
  useEffect(() => {
    getData(`api/mainsettings`).then(
      (response) => {
        const data = response?.data?.data;
        setMainsettings(data);
      },
      (error) => {
        setMainsettings([]);
      }
    );
    getData(`api/can-service`).then(
      (response) => {
        const data = response.data.data;
        setService(data);
        cookies.set("service", data, { path: "/" });
      },
      (error) => {
        const errors = error?.response?.data;
        setErrorsObj(errors);
        setService(0);
        cookies.set("service", 0, { path: "/" });
      }
    );
    getData(`api/can-message`).then(
      (response) => {
        const data = response.data.data;
        setMessage(data);
        cookies.set("message", data, { path: "/" });
      },
      (error) => {
        const errors = error?.response?.data;
        setErrorsObj(errors);
        setMessage(0);
      }
    );
    getData(`api/check-closed`).then((res) => {
      const open = res.data.data;
      setOpen(open);
      if (open === false) {
        setCloseMessage(res.data.message);
      }
    });
  }, []);

  const clearCookies = () => {
    const allCookies = cookies.getAll();
    //loop through all the cookies and delete them
    Object.keys(allCookies).forEach((cookie) => {
      cookies.remove(cookie);
    });
  };

  // axios interceptors to send token with every request

  axios.interceptors.request.use((config) => {
    const token = cookies.get("token");
    config.headers.Authorization = `Bearer ${token || ""}`;
    return config;
  });

  axios.interceptors.response.use(
    (response) => {
      // If the response is successful, return it
      return response;
    },
    (error) => {
      if (
        error?.response?.status === 401 &&
        window.location.pathname !== "/login"
      ) {
        clearCookies();
        toast.error("يجب عليك تسجيل الدخول أولا", {
          toastId: 401,
        });
      }
      if (error?.response?.status === 410) {
        toast.error(error?.response?.data?.message, {
          toastId: 410,
        });
      }
      if (error?.response?.status === 411) {
        toast.error(error?.response?.data?.message, {
          toastId: 411,
        });
      }
      // If the error is not a 401 error, forward the error to be handled by the calling code
      return Promise.reject(error);
    }
  );

  return (
    <div className="App">
      <Helmet>
        <title>{mainsettings?.title?.ar}</title>
        <meta name="description" content="Home" />
      </Helmet>
      {open === false ? (
        <div
          className="site_message_closed  bg-img1 size1 flex-w flex-c-m p-t-55 p-b-55 p-l-15 p-r-15"
          style={{
            backgroundImage: `url('https://colorlib.com/etc/cs/comingsoon_03/images/bg01.jpg')`,
          }}
        >
          <div className="wsize1 bor1 bg1 p-t-175 p-b-45 p-l-15 p-r-15 respon1">
            <div className="wrappic1">
              <LazyLoadImage src={mainsettings?.logo?.ar} alt="LOGO" />
              <h5 className="txt-center m1-txt1 p-t-33 p-b-68">
                الموقع مغلق مؤقتاً
              </h5>
              <hr />
              <p>{closeMessage}</p>
            </div>
          </div>
        </div>
      ) : (
        <BrowserRouter>
          <SearchProvider>
            <Header
              mainsettings={mainsettings}
              data={service}
              message={message}
              errorsObj={errorsObj}
            />

          </SearchProvider>
          <Ticker />
          <AppRoutes />
          <AlertMessage />

          <Footer
            mainsettings={mainsettings}
            data={service}
            errorsObj={errorsObj}
          />
        </BrowserRouter>
      )}
    </div>
  );
}

export default App;
