import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { AiOutlineMail } from "react-icons/ai";
import { GoLocation } from "react-icons/go";
import { ImMobile } from "react-icons/im";
import { getCookie } from "../../Components/Auth/ForCookie";
import Loading from "../../Components/Blocks/Loading";
import { createData, getData } from "../../Services/DataService";
import "./index.scss";
import useScrollToTop from "../../hooks/useScrollToTop";
import ReCAPTCHA from "react-google-recaptcha";

const ContactUS = () => {
  const [isUser, setIsUser] = useState(true);
  const [contactName, setContactName] = useState("");
  const [contactEmail, setContactEmail] = useState("");
  const [contactSubject, setContactSubject] = useState("");
  const [contactType, setContactType] = useState("");
  const [contactMessage, setContactMessage] = useState("");
  const [errorLog, setErrorLog] = useState({});
  const [sucessSend, setSucessSend] = useState(false);
  const [mail, setMail] = useState("");
  const [mobile, setMobile] = useState("");
  const [address, setAddress] = useState("");
  const [loading, setLoading] = useState(true);
  const [recaptcha, setRecaptcha] = useState(null);
  const recaptchaRef = React.createRef();

  useEffect(() => {
    if (getCookie("userId")) {
      getData(`api/user-profile/${getCookie("userId")}`).then(
        (response) => {
          setContactName(response.data.data.name);
          setContactEmail(response.data.data.email);
        },
        (error) => {
          setIsUser(false);
        }
      );
    }
    getData(`api/mainsettings`).then((response) => {
      setMail(response.data.data.email);
      setMobile(response.data.data.mobile);
      setAddress(response.data.data.address.ar);
      setLoading(false);
    });
  }, []);

  const handleSubmit = (e) => {
    setErrorLog({});
    e.preventDefault();
    const formData = new FormData();
    formData.append("name", contactName);
    formData.append("type", contactType);
    formData.append("email", contactEmail);
    formData.append("subject", contactSubject);
    formData.append("recaptcha", recaptcha);
    formData.append("message", contactMessage);

    createData(`api/contact-us`, formData).then(
      (response) => {
        window.scrollTo({ top: 0, behavior: "smooth" });
        setSucessSend(true);
        setTimeout((props) => {
          setSucessSend(false);
        }, 5000);
        setContactName("");
        setContactEmail("");
        setContactType("");
        setRecaptcha(null);
        setContactSubject("");
        setContactMessage("");
      },
      (error) => {
        setErrorLog(error.response.data);
        window.scrollTo({ top: 0, behavior: "smooth" });
      }
    );
  };

  useScrollToTop();
  function onChangeCaptcha(value) {
    setRecaptcha(value);
  }

  return (
    <>
      <Helmet>
        <title>تواصل معنا</title>
        <meta name="description" content="Contact Us" />
      </Helmet>
      {Loading === true ? (
        <Loading />
      ) : (
        <section className="contact-us-page-parent">
          <Container>
            <Row>
              <Col xs={12} lg={10} className="mx-auto">
                <div className="form-heading">
                  <h1>اتصل بنا</h1>
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={12} lg={10} className="mx-auto">
                <div className="contact_Info">
                  <p>
                    <GoLocation />: {address}
                  </p>
                  <p>
                    <a
                      href={`mailto:${mail}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <AiOutlineMail />: {mail}
                    </a>
                  </p>
                  <p>
                    <a href={`tel:${mobile}`}>
                      <ImMobile />: {mobile}
                    </a>
                  </p>
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={12} lg={8} className="mx-auto">
                {sucessSend && (
                  <div className="sucess-alert mt-4">
                    <FontAwesomeIcon
                      className="alert-icon"
                      icon={faCheckCircle}
                    ></FontAwesomeIcon>
                    <span>شكرا لقد تم ارسال رسالتك بنجاح</span>
                  </div>
                )}
                <Form
                  className="Contact-us-form FormParent"
                  onSubmit={handleSubmit}
                >
                  <Form.Group
                    className={`${
                      errorLog && errorLog.name ? "has-error" : null
                    }`}
                  >
                    <Form.Label>الاسم</Form.Label>
                    <Form.Control
                      type="text"
                      id="contactName"
                      value={contactName}
                      onChange={(e) => setContactName(e.target.value)}
                      placeholder={contactName}
                    />
                    {errorLog && errorLog.name ? (
                      <div className="errors-parent">
                        <span className="error-txt">{errorLog.name.ar}</span>
                      </div>
                    ) : null}
                  </Form.Group>

                  <Form.Group
                    className={`${
                      errorLog && errorLog.email ? "has-error" : null
                    }`}
                  >
                    <Form.Label>البريد الالكتروني</Form.Label>
                    <Form.Control
                      type="email"
                      id="contactEmail"
                      value={contactEmail}
                      onChange={(e) => setContactEmail(e.target.value)}
                      placeholder={contactEmail}
                    />
                    {errorLog && errorLog.email ? (
                      <div className="errors-parent">
                        <span className="error-txt">{errorLog.email.ar}</span>
                      </div>
                    ) : null}
                  </Form.Group>

                  <Row>
                    {" "}
                    <Col>
                      <Form.Group
                        className={`${
                          errorLog && errorLog.subject ? "has-error" : null
                        }`}
                      >
                        <Form.Label>الموضوع</Form.Label>
                        <Form.Control
                          type="text"
                          id="contactSubject"
                          value={contactSubject}
                          onChange={(e) => setContactSubject(e.target.value)}
                          placeholder={contactSubject}
                        />
                        {errorLog && errorLog.subject ? (
                          <div className="errors-parent">
                            <span className="error-txt">
                              {errorLog.subject.ar}
                            </span>
                          </div>
                        ) : null}
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group>
                        <Form.Label>التصنيفات</Form.Label>
                        <select className="categories"
                                onChange={(e) => setContactType(e.target.value)}
                                required={true}>
                          <option value="">اختر التصنيف</option>
                          <option value="Complaint">شكوي</option>
                          <option value="Suggestion">اقتراح</option>
                          <option value="Inquiry">استفسار</option>
                        </select>
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <Form.Group
                        className={`${
                          errorLog && errorLog.message ? "has-error" : null
                        }`}
                      >
                        <Form.Label>الرسالة</Form.Label>
                        <Form.Control
                          as="textarea"
                          id="contactMessage"
                          value={contactMessage}
                          onChange={(e) => setContactMessage(e.target.value)}
                        />
                        {errorLog && errorLog.message ? (
                          <div className="errors-parent">
                            <span className="error-txt">
                              {errorLog.message.ar}
                            </span>
                          </div>
                        ) : null}
                      </Form.Group>
                    </Col>
                    <Col className="d-flex justify-content-center align-items-center">
                      <div className="d-flex justify-content-center align-items-center">
                        <ReCAPTCHA
                          sitekey={process.env.REACT_APP_SITE_KEY}
                          onChange={onChangeCaptcha}
                          size="normal"
                        />
                      </div>
                      <div>
                          {errorLog && errorLog.recaptcha ? (
                              <div className="errors-parent">
                                  <span className="error-txt">
                                    {errorLog.recaptcha.ar}
                                  </span>
                              </div>
                          ) : null}
                      </div>
                    </Col>
                  </Row>

                  <Button type="submit" className="btn primary-btn mt-3" disabled={!recaptcha}>
                    إرسال
                  </Button>
                </Form>
              </Col>
            </Row>
          </Container>
        </section>
      )}
    </>
  );
};

export default ContactUS;
