import React, { useEffect, useState } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { NavLink, useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faUser,
  faClock,
  faCommentDots,
  faChevronLeft,
  faChevronRight,
  faThLarge,
  faThList,
} from "@fortawesome/free-solid-svg-icons";
import { getCookie } from "../../../Components/Auth/ForCookie";
import { getData } from "../../../Services/DataService";
import "./index.scss";
import Loading from "../../../Components/Blocks/Loading";
import Slider from "react-slick";
import { Helmet } from "react-helmet";
import Cookies from "universal-cookie";

const DealsList = () => {
  const sliderSettings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    autoPlay: true,
    slidesToScroll: 1,
    autoPlaySpeed: 500,
  };
  const [deals, setDeals] = useState("");
  const [currentPage, setCurrentPage] = useState("");
  const [nextPage, setNextPage] = useState("");
  const [prevPage, setPrevPage] = useState("");
  const [lastPage, setLastPage] = useState("");
  const [currentLink, setCurrentLink] = useState("");
  const [totalPages, setTotalPages] = useState("");
  const [last_page, setLast_page] = useState("");
  const [Pages, setPages] = useState([]);
  const [gridView, setGridView] = useState(false);
  const [dealSearchName, setDealSearchName] = useState("");
  const [dealSearchType, setDealSearchType] = useState("");
  const [loading, setLoading] = useState(true);
  const [errorsLog, setErrorsLog] = useState({});
  const [dealsCategories, setDealsCategories] = useState([]);
  const [dealsCategory, setDealsCategory] = useState([]);

  useEffect(() => {
    getData(`api/services`).then(
      (response) => {
        const data = response?.data?.data;
        setDeals(data.data);
        setLoading(false);
        setCurrentPage(data.current_page);
        setNextPage(data.next_page_url);
        setPrevPage(data.prev_page_url);
        setLastPage(data.last_page_url);
        setTotalPages(data.last_page);
        setCurrentLink(data.path);
        setLast_page(data.last_page);
        setPages(response?.data.pages);
      },
      (error) => {
        setDeals([]);
        setLoading(false);
      }
    );
  }, []);
  useEffect(() => {
    getData(`api/services-category`).then(
      (response) => {
        const dealsCategories = response?.data?.data;
        setDealsCategories(dealsCategories);
        // setDealsCategory(dealsCategories[0].id);
      },
      (error) => {
        setDealsCategories([]);
      }
    );
  }, []);

  const fillData = (url) => {
    getData(url).then(
      (response) => {
        const data = response?.data?.data;
        setDeals(data.data);
        setCurrentPage(data.current_page);
        setNextPage(data.next_page_url);
        setPrevPage(data.prev_page_url);
        setLastPage(data.last_page_url);
        setTotalPages(data.last_page);
        setCurrentLink(data.path);
        setLast_page(data.last_page);
        setPages(response?.data?.pages);
        window.scrollTo({ top: 0, behavior: "smooth" });
      },
      (error) => {
        setDeals([]);
        window.scrollTo({ top: 0, behavior: "smooth" });
      }
    );
  };
  const hanldeView = () => {
    setGridView(!gridView);
  };

  const handleNextPage = (id) => {
    fillData(nextPage);
  };

  const handlePrevPage = () => {
    fillData(prevPage);
  };

  const handleGoToPage = (id) => {
    let pageNumber = id;
    fillData(`${currentLink}?page=${pageNumber}`);
  };

  const pages = Pages.length
    ? Pages.map((page, index) => {
        return (
          <li
            key={index}
            className={Number(currentPage) === page ? `active` : ""}
            onClick={() => handleGoToPage(page)}
          >
            <button>{page}</button>
          </li>
        );
      })
    : null;

  const handleSearchDeals = (e) => {
    e.preventDefault();
    getData(
      `api/services?search=${
        dealSearchName.length > 0 ? dealSearchName : ""
      }&type=${dealSearchType.length > 0 ? dealSearchType : ""}&category=${
        dealsCategory.length > 0 ? dealsCategory : ""
      }`
    ).then(
      (response) => {
        const data = response?.data?.data;
        setDeals(data?.data);
        setCurrentPage(data.current_page);
        setNextPage(data.next_page_url);
        setPrevPage(data.prev_page_url);
        setLastPage(data.last_page_url);
        setTotalPages(data.last_page);
        setCurrentLink(data.path);
        setLast_page(data.last_page);
        setPages(response?.data.pages);
        setLoading(false);
      },
      (error) => {
        setDeals([]);
        if (error) {
          const errors = error?.response?.data;
          setErrorsLog(errors);
          window.scrollTo({ top: 0, behavior: "smooth" });
        }
      }
    );
  };
  const history = useHistory();
  const cookie = new Cookies();

  return (
    <>
      <Helmet>
        <title>الصفقات</title>
      </Helmet>
      <section className="Deal-list-sec">
        <Container>
          {/* <Sugar customLoading={loading} color={'#00A388'} background="#87D4C7"/> */}
          {loading && <Loading />}
          <Row>
            <Col xs={12}>
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <NavLink to="/" className="bredcrumb-link">
                    الرئيسية
                  </NavLink>
                </li>

                <li className="breadcrumb-item">
                  <span className="mob-bredcrumb active">
                    صفقات البيع و الشراء
                  </span>
                </li>
              </ul>
            </Col>
          </Row>

          <Row className="justify-content-between mb-4">
            <Col xs={12} lg={7}>
              <Form className="FormParent" onSubmit={handleSearchDeals}>
                <Form.Group
                  className={`${
                    errorsLog && errorsLog.titleAr ? "has-error" : ""
                  }`}
                >
                  <Form.Control
                    id="dealSearchName"
                    onChange={(e) => setDealSearchName(e.target.value)}
                    type="text"
                    placeholder="البحث عن الصفقات"
                  />
                  {errorsLog && errorsLog.titleAr ? (
                    <div className="errors-parent">
                      <span className="error-txt">
                        {errorsLog?.titleAr?.ar}
                      </span>
                    </div>
                  ) : null}
                </Form.Group>

                <Form.Group
                  className={`mx-3 ${
                    errorsLog && errorsLog.dealsCategory ? "has-error" : ""
                  }`}
                >
                  <Form.Control
                    as="select"
                    className={`form-select ${
                      errorsLog && errorsLog.dealsCategory ? "has-error" : null
                    }`}
                    id="dealsCategory"
                    onChange={(e) =>
                      setDealsCategory(e.target[e.target.selectedIndex].id)
                    }
                  >
                    <option value="" selected disabled hidden>
                      {" "}
                      التصنيفات
                    </option>
                    {dealsCategories?.map((cat) => {
                      return (
                        <>
                          {
                            <option
                              id={cat?.id}
                              key={cat?.id}
                              value={cat?.title?.ar}
                            >
                              {cat?.title?.ar}
                            </option>
                          }
                        </>
                      );
                    })}
                  </Form.Control>

                  {errorsLog && errorsLog.used_status ? (
                    <div className="errors-parent">
                      <span className="error-txt">
                        {errorsLog.dealsCategory}
                      </span>
                    </div>
                  ) : null}
                </Form.Group>

                <Form.Group
                  className={`mx-3 ${
                    errorsLog && errorsLog.used_status ? "has-error" : ""
                  }`}
                >
                  <Form.Control
                    as="select"
                    className={`form-select ${
                      errorsLog && errorsLog.used_status ? "has-error" : null
                    }`}
                    id="dealSearchType"
                    onChange={(e) =>
                      setDealSearchType(e.target[e.target.selectedIndex].id)
                    }
                  >
                    <option value="" selected disabled hidden>
                      {" "}
                      النوع
                    </option>
                    <option id="1">عرض</option>
                    <option id="2">طلب</option>
                  </Form.Control>

                  {errorsLog && errorsLog.used_status ? (
                    <div className="errors-parent">
                      <span className="error-txt">
                        {errorsLog.used_status.ar}
                      </span>
                    </div>
                  ) : null}
                </Form.Group>

                <Button className="btn primary-btn flx-centered" type="submit">
                  ابحث
                </Button>
              </Form>
            </Col>

            <Col xs={12} lg={2}>
              {getCookie("loginned") && cookie.get("service") == 1 && (
                <div className="add-new w-100">
                  <NavLink
                    to="/deals/add"
                    className="red-btn btn xl-btn inline-centerd w-100"
                  >
                    انشاء صفقة جديدة
                  </NavLink>
                </div>
              )}
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <div className="new-offer">
                <div className="controls">
                  <div
                    className={`grid ${gridView == true && "active"}`}
                    onClick={hanldeView}
                  >
                    <FontAwesomeIcon
                      icon={faThLarge}
                      className="control-icon"
                    />
                  </div>
                  <div
                    className={`list ${gridView == false && "active"}`}
                    onClick={hanldeView}
                  >
                    <FontAwesomeIcon icon={faThList} className="control-icon" />
                  </div>
                </div>
              </div>
            </Col>

            {deals && deals.length > 0 ? (
              deals.map((item) => {
                return (
                  <Col xs={12} lg={gridView && 4} key={item.id}>
                    <div
                      className={`offers-list flx-centered ${gridView &&
                        "grid-parent"}`}
                    >
                      <div className="cont flx-centered">
                        <div
                          className="cont-img"
                          onClick={() => {
                            history.push(`/deals/${item.id}`);
                          }}
                        >
                          {item.images.length > 1 ? (
                            <Slider
                              dots={false}
                              slidesToShow={1}
                              slidesToScroll={1}
                              infinite={true}
                              autoplay={true}
                              autoplaySpeed={2000}
                              arrows={false}
                            >
                              {item.images.map((img, index) => {
                                return (
                                  <div
                                    key={index}
                                    style={{
                                      width: "100%",
                                      height: "76px",
                                    }}
                                  >
                                    <img src={`${img}`} alt={item?.title?.ar} />
                                  </div>
                                );
                              })}
                              {/* <img
                            src={`${item.images[0]}`}
                            alt={item?.title?.ar}
                          /> */}
                            </Slider>
                          ) : (
                            <img src={item?.images[0]} alt={item?.title?.ar} />
                          )}
                        </div>
                        <div className="cont-txt">
                          <h2>
                            <NavLink to={`/deals/${item.id}`}>
                              {item?.title?.ar.length > 20
                                ? item?.title?.ar?.slice(0, 20).concat("...")
                                : item?.title?.ar}
                            </NavLink>
                          </h2>
                          <div className="offer-details">
                            <div className="service-user">
                              <FontAwesomeIcon
                                icon={faUser}
                                className="register-icon"
                              />
                              <span>{item.user_id.name}</span>
                            </div>

                            <div>
                              <FontAwesomeIcon
                                icon={faClock}
                                className="register-icon"
                              />
                              <span>{item.created_at}</span>
                            </div>

                            <div>
                              <FontAwesomeIcon
                                icon={faCommentDots}
                                className="register-icon"
                              />
                              <span>{item.order_count} متقدم</span>
                            </div>

                            <div>
                              <FontAwesomeIcon
                                icon={faEye}
                                className="register-icon"
                              />
                              <span>{item.views} مشاهدة</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="submit-offer">
                        <NavLink
                          to={`/deals/${item.id}`}
                          className="btn primary-btn"
                        >
                          قدم عرض
                        </NavLink>
                      </div>
                    </div>
                  </Col>
                );
              })
            ) : (
              <Col xs={12} lg={7} className="no-content-show mx-auto">
                <div className="cont-txt">
                  <p>لا يوجد صفقات لعرضها</p>
                </div>
              </Col>
            )}
          </Row>
          <Col xs={12} lg={9} className="pagination-parent-books">
            {parseInt(totalPages) > 1 ? (
              <ul className="pagination-parent flx-centered">
                <li className="first-page">
                  <button
                    onClick={handlePrevPage}
                    className={
                      currentPage == "1" ? `disabled-btn` : `arrow-btn`
                    }
                  >
                    <FontAwesomeIcon icon={faChevronRight} />
                  </button>
                </li>

                {currentPage != 1 && currentPage != 2 && (
                  <li className="test-last" onClick={() => handleGoToPage(1)}>
                    <button>1</button>
                  </li>
                )}
                {currentPage != 1 && currentPage != 2 && (
                  <li className="to-continue">...</li>
                )}

                {pages}

                {totalPages != currentPage &&
                  currentPage != Number(totalPages) - 1 && (
                    <li className="to-continue">...</li>
                  )}
                {totalPages != currentPage &&
                  currentPage != Number(totalPages) - 1 && (
                    <li
                      className="test-last"
                      onClick={() => handleGoToPage(totalPages)}
                    >
                      <button>{totalPages}</button>
                    </li>
                  )}

                {/* {currentPage != } */}
                <li className="last-page">
                  <button
                    onClick={handleNextPage}
                    className={
                      currentPage == totalPages ? `disabled-btn` : `arrow-btn`
                    }
                  >
                    <FontAwesomeIcon icon={faChevronLeft} />
                  </button>
                </li>
              </ul>
            ) : null}
          </Col>
        </Container>
      </section>
    </>
  );
};

export default DealsList;
