/* eslint-disable eqeqeq */
import SearchServicesResult from "./SearchResultDeals";
import SearchBooksResult from "./SearchResultBooks";
import React from "react";
import { useLocation } from "react-router-dom";
import SearchResultMembers from "./SearchResultMembers";
const SearchIndex = (props) => {
  const { search } = useLocation();
  return (
    <>
      {props.match.params.type == "deals" ? (
        <SearchServicesResult searchData={search.slice(1)} />
      ) : props.match.params.type == "books" ? (
        <SearchBooksResult searchData={search.slice(1)} />
      ) : (
        <SearchResultMembers searchData={search.slice(1)} />
      )}
    </>
  );
};

export default SearchIndex;
